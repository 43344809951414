(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('CertificationController', CertificationController);

    CertificationController.$inject = ['DataUtils', 'Certification'];

    function CertificationController(DataUtils, Certification) {

        var vm = this;

        vm.certifications = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            Certification.query(function(result) {
                vm.certifications = result;
                vm.searchQuery = null;
            });
        }
    }
})();
