(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('MoveApplicationToNextStageDialogController', MoveApplicationToNextStageDialogController);

    MoveApplicationToNextStageDialogController.$inject = ['$uibModalInstance', 'entity', 'Application', 'AlertService', '$rootScope', '$stateParams', '$state'];

    function MoveApplicationToNextStageDialogController($uibModalInstance, entity, Application, AlertService, $rootScope, $stateParams, $state) {
        var vm = this;

        vm.application = entity;
        vm.clear = clear;
        vm.confirmAdvance = confirmAdvance;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmAdvance(id) {

            vm.stagesModel = {};
            vm.stagesModel.observation = vm.observations;
            vm.stagesModel.applicationID = id;
            vm.stagesModel.remove = false;

            Application.observationAndAction(vm.stagesModel, function (result) {
                    $uibModalInstance.dismiss();
                    $rootScope.$broadcast('application-set');
                    $state.reload('vacancy-detail', {id: $stateParams.id});
                },
                function (error) {
                    AlertService.warning('Não foi possível mudar a fase do candidato' + vm.application.applicant.name + ' tente novamente');
                });
        }
    }
})();
