(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantDeleteController',ApplicantDeleteController);

    ApplicantDeleteController.$inject = ['$uibModalInstance', 'entity', 'Applicant', '$state'];

    function ApplicantDeleteController($uibModalInstance, entity, Applicant, $state) {
        var vm = this;

        vm.applicant = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Applicant.delete({id: $state.params.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
