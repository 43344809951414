/**
 * Created by User on 3/23/2017.
 */

(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('NewVacancyController', NewVacancyController);

    NewVacancyController.$inject = ['$timeout', '$scope', '$state', '$http', 'Category', 'City', 'Application', 'Language', 'RequirementFilter', 'AcademicDegree', '$uibModal', 'Vacancy', 'Principal', 'DataUtils', 'Company', '$translate', '$window', '$rootScope', 'Degree'];

    function NewVacancyController($timeout, $scope, $state, $http, Category, City, Application, Language, RequirementFilter, AcademicDegree, $uibModal, Vacancy, Principal, DataUtils, Company, $translate, $window, $rootScope, Degree) {
        var vm = this;

        if($state.params.id!==null){
            vm.vacancy = Vacancy.get({id:$state.params.id}, function (result) {
                vm.requirementFilter = result.requirementFilter;
                RequirementFilter.get({id: result.requirementFilter.id}, function (result){
                    vm.requirementFilter =result;
                    vm.ms = vm.requirementFilter.maritalStatus.split(",");
                    vm.requirementFilter.maritalStatus = [];
                    if(angular.isDefined(vm.ms[0])){
                        vm.requirementFilter.maritalStatus.push(vm.ms[0])
                    }
                    if(angular.isDefined(vm.ms[1])){
                        vm.requirementFilter.maritalStatus.push(vm.ms[1])
                    }
                    if(angular.isDefined(vm.ms[2])){
                        vm.requirementFilter.maritalStatus.push(vm.ms[2])
                    }
                    if(angular.isDefined(vm.ms[3])){
                        vm.requirementFilter.maritalStatus.push(vm.ms[3])
                    }

                    if(vm.requirementFilter.gender=='MALE'){
                        vm.isMale=true;
                    }else if(vm.requirementFilter.gender=='FEMALE'){
                        vm.isFemale=true;
                    }else if(vm.requirementFilter.gender=='BOTH'){
                        vm.isFemale=true;
                        vm.isMale=true;
                    }
                })

            });



            vm.isUpdate = !$state.params.isReplica;
            if(!vm.isUpdate){
                vm.vacancy.id=null;
            }
        }else{
            vm.vacancy = {};
            vm.requirementFilter = {};
            vm.requirementFilter.academicDegrees = [];
            vm.isUpdate=false;
        }


        vm.model = {};
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.categories = Category.query({size:5000});
        vm.cities = City.query({size:5000});
        //vm.applications = Application.query();
        vm.languages = Language.query({size:5000});
        //vm.academicDegree = AcademicDegree.query({size:5000});
        vm.degrees = Degree.query({size:5000});
        vm.company = Company.query(function () {
            if(!angular.isDefined(vm.vacancy.howToApply)){
                if(angular.isDefined(vm.company[0].howToApplyText)){
                    vm.vacancy.howToApply=vm.company[0].howToApplyText;
                }
            }
        });


        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        vm.maritalStatus=['CASADO', 'SOLTEIRO', 'VIUVO','DIVORCIADO'];


        var index = 0;
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });
        getAccount();


        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.vacancy.addedBy = vm.account;
            });
        }

        function save() {

            vm.isSaving = true;

            if(vm.isMale && vm.isFemale){
                vm.requirementFilter.gender='BOTH';
            } else if(vm.isMale){
                vm.requirementFilter.gender='MALE';
            } else if(vm.isMale){
                vm.requirementFilter.gender='FEMALE';
            }
            vm.requirementFilter.maritalStatus=vm.requirementFilter.maritalStatus.toString();

            vm.model.vacancy = vm.vacancy;
            vm.model.requirementFilter = vm.requirementFilter;

            if($state.params.id==null || !vm.isUpdate){
                //console.debug(vm.model)
                if(!vm.isUpdate){
                    vm.model.vacancy.id=null;
                    vm.model.requirementFilter.id=null;
                }
                $http({
                    method: 'POST',
                    url: '/api/vacancies-save',
                    data: vm.model
                }).then(onSaveSuccess,onSaveError);
            }else{
                //console.debug("update vacancy")
                $http({
                    method: 'POST',
                    url: '/api/vacancies-update',
                    data: vm.model
                }).then(onSaveSuccess,onSaveError);
            }

        }

        function onSaveSuccess(vacancyResult) {
            $translate('hrmApp.swalMessages.vacancySavedSuccess').then(function (translation) {
                swal({
                    title: "Sucesso!",
                    text: translation,
                    type: "success",
                    confirmButtonText: "OK"
                });
            });
            $rootScope.$broadcast('updateCount');
            $state.go('vacancy', {reload: true});
            vm.isSaving = false;
        }

        function onSaveError() {
            $translate('hrmApp.swalMessages.vacancySavedError').then(function (translation) {
                swal({
                    title: "Erro!",
                    text: translation,
                    type: "error",
                    confirmButtonText: "OK"
                });
            });
            //AlertService.error('Erro ao gravar, tente novamente ...');
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.finalDate = false;
        vm.datePickerOpenStatus.minimumAge = false;
        vm.datePickerOpenStatus.maximumAge = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.openAcademicDegree = function () {
            $uibModal.open({
                templateUrl: 'app/entities/academic-degree/academic-degree-to-vacancy-dialog.html',
                controller: 'AcademicDegreeToVacancyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            address: null,
                            primaryPhoneNumber: null,
                            secondaryPhoneNumber: null,
                            fax: null,
                            postalCode: null,
                            email: null,
                            contactPerson: null,
                            website: null,
                            id: null
                        };
                    }
                }
            }).result.then(function (result) {
                vm.requirementFilter.academicDegrees[index] = result;
                index++;
            }, function () {
            });

            vm.removeDegree = function () {
                vm.requirementFilter.academicDegrees.split(index, 1);
                index--;
            };
        };


        vm.setFile = function ($file, vacancy) {

            var value=$file.name;
            var ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();
            if(ext=='pdf'){
                if($file.size>1048576){
                    $translate('hrmApp.swalMessages.fileSizeError').then(function (translation) {
                        swal("", translation, "error");
                    });
                }else{
                    if ($file) {
                        DataUtils.toBase64($file, function(base64Data) {
                            $scope.$apply(function() {
                                vacancy.file = base64Data;
                                vacancy.fileContentType = $file.type;
                            });
                        });
                    }
                }
            } else{
                $translate('hrmApp.swalMessages.fileFormatErrorDoc').then(function (translation) {
                    swal("", translation, "error");
                });
            }
        };

        vm.clear = function () {
            $window.history.back();
        }
    }
})();
