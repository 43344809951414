(function () {
    'use strict';
    angular
        .module('hrmApp')
        .factory('Application', Application);

    Application.$inject = ['$resource', 'DateUtils'];

    function Application($resource, DateUtils) {
        var resourceUrl = 'api/applications/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getApplicantRelated': {
                method: 'GET',
                url: 'api/applicant-applications/:id',
                isArray: true
            },
            'getVacancyApplications': {
                method: 'GET',
                url: 'api/get-vacancy-applications/:vacancyId',
                isArray: true
            },
            'vacancyApplicantReport': {
                method: 'GET',
                url: 'api/vacancy-applicant-report',
                isArray: false
            },
            'observationAndAction': {
                method: 'POST',
                url: 'api/observation-and-action'
            },
            'applicationMultipleAction': {
                method: 'PUT',
                url: 'api/application-multiple-action'
            },
            'applicantFilterModel': {
                method: 'PUT',
                url: 'api/applicant-filter-model',
                isArray: true
            },
            'deleteByUser': {
                method: 'DELETE',
                url: 'api/applications/:id/:user'
            },
            'getApplicantCSV': {
                method: 'GET',
                url: 'api/applicants-csv-download/:applicationsIDs'
            }
        });
    }
})();
