(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['pagingParams', 'paginationConstants', 'ParseLinks', 'AlertService', '$scope', 'Principal', 'LoginService', '$state', 'Vacancy', 'Category', 'City', 'Application', 'Applicant', 'Language', '$translate', '$http'];

    function HomeController(pagingParams, paginationConstants, ParseLinks, AlertService, $scope, Principal, LoginService, $state, Vacancy, Category, City, Application, Applicant, Language, $translate, $http) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.categories = Category.getVacancyCategories();
        vm.cities = City.getVacancyCities();
        //vm.applications = Application.query();
        //vm.languages = Language.query();
        vm.register = register;
        vm.spinner = true;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        if(Principal.isAuthenticated){
            getAccount();
        }

        vm.vacancies = [];
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.showLeftFilters = true;
        vm.loadAll();


        function loadAll() {
            if (pagingParams.search) {
                Vacancy.getHomeVacancies({
                    filterModel: vm.filterModel,
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Vacancy.getHomeVacancies({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vacancies = data;
                vm.page = pagingParams.page;
                vm.spinner = false;

                //console.debug(vm.vacancies);

                if(vm.vacancies.length <= 0){
                    vm.showLeftFilters = false;
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.spinner = false;
            }
        }

        vm.searchAll = function () {

            Vacancy.vacancySearchFilter({
                category: vm.category,
                city: vm.city,
                searchParam: vm.searchParam,
                date: vm.date,
                query: pagingParams.search,
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vacancies = data;
                vm.page = pagingParams.page;
                if(vm.searchParam!=null){
                    vm.isSearch=true;
                }

                //vm.searchParam = '';
                //console.debug(vm.vacancies);
            }

            function onError(error) {
                vm.searchParam = '';
                AlertService.error(error.data.message);
            }
        };

        vm.searchTitle = function (searchText) {

            //console.debug(searchText);
            Vacancy.getSearchVacancies({
                searchParam: vm.searchParam
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vacancies = data;
                vm.page = pagingParams.page;
                if(vm.searchParam!=null){
                    vm.isSearch=true;
                }

                //vm.searchParam = '';
                //console.debug(vm.vacancies);
            }

            function onError(error) {
                vm.searchParam = '';
                AlertService.error(error.data.message);
            }
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;

                vm.isAuthenticated = Principal.isAuthenticated;
                if(vm.isAuthenticated && vm.account!==null){
                    if(vm.account.authorities.indexOf('ROLE_APPLICANT') !== -1 && !(vm.account.authorities.indexOf('ROLE_ADMIN') !== -1)
                    ){
                        Applicant.getApplicantFromUser({userId:vm.account.id}, function (result) {

                            var incomplete=false;
                            if(result.name==null){
                                incomplete=true;
                                vm.incomplete = true;
                                vm.name=vm.account.login
                            }else{
                                vm.name=result.name;
                            }
                            if(result.primaryContact==null || result.email==null ){
                                incomplete=true;
                                vm.incomplete = true;
                            }
                            if(result.address==null){
                                incomplete=true;
                                vm.incomplete = true;
                            }
                            if(result.languages==null){
                                incomplete=true;
                            }
                            /*
                            if(vm.personDocuments){
                             percentage=percentage+20;
                             }
                             if(vm.academicDegrees){
                             percentage=percentage+20;
                             }
                             if(vm.previousJobs){
                             percentage=percentage+20;
                             }*/
                            /*
                            if(incomplete){

                                $translate('hrmApp.swalMessages.incompleteProfile.title').then(function (translation) {
                                    vm.title=translation;
                                    $translate('hrmApp.swalMessages.incompleteProfile.message1').then(function (translation) {
                                        vm.message1=translation;
                                        $translate('hrmApp.swalMessages.incompleteProfile.btn').then(function (translation) {
                                            vm.btn=translation;
                                            $translate('hrmApp.swalMessages.incompleteProfile.message2').then(function (translation) {
                                                swal(
                                                    {
                                                        title: vm.title,
                                                        text: vm.message1,
                                                        type: "warning",
                                                        showCancelButton: false,
                                                        confirmButtonColor: "#DD6B55",
                                                        confirmButtonText: vm.btn,
                                                        closeOnConfirm: false
                                                    },
                                                    function(){
                                                        swal(vm.title, translation, "success");
                                                        $state.go('applicant-profile');
                                                    }
                                                );
                                            });

                                        });

                                    });

                                });


                            }*/

                        })


                        $http({
                            method: 'GET',
                            url: '/api/applicants/data/'+vm.account.id
                        }).then(function (response) {
                            vm.percentage = response.data;

                            if(vm.percentage<60){
                                $translate('hrmApp.swalMessages.incompleteProfile.title').then(function (translation) {
                                    vm.title=translation;
                                    $translate('hrmApp.swalMessages.incompleteProfile.message1').then(function (translation) {
                                        vm.message1=translation;
                                        $translate('hrmApp.swalMessages.incompleteProfile.btn').then(function (translation) {
                                            vm.btn=translation;
                                            $translate('hrmApp.swalMessages.incompleteProfile.message2').then(function (translation) {
                                                swal(
                                                    {
                                                        title: vm.title,
                                                        text: vm.message1,
                                                        type: "warning",
                                                        showCancelButton: false,
                                                        confirmButtonColor: "#DD6B55",
                                                        confirmButtonText: vm.btn,
                                                        closeOnConfirm: false
                                                    },
                                                    function(){
                                                        swal(vm.title, translation, "success");
                                                        $state.go('applicant-profile');
                                                    }
                                                );
                                            });

                                        });

                                    });

                                });
                            }
                        })
                    }
                }
            });
        }

        function register() {
            $state.go('register');
        }

        vm.searchFilter = function (searchParam, identifier) {
            if (identifier === 'date') {
                vm.city = -1;
                vm.searchParam = ' ';
                vm.date = searchParam;
                vm.category = -1;
            }
            if (identifier === 'category') {
                vm.city = -1;
                vm.searchParam = ' ';
                vm.date = 'undefined';
                vm.category = searchParam;
            }
            if (identifier === 'city') {
                vm.city = searchParam;
                vm.searchParam = ' ';
                vm.date = 'undefined';
                vm.category = -1;
            }

            vm.searchAll();
        };

        vm.applyToVacancy = function (vacancy) {
            var userId = vm.account.id;
            Applicant.getApplicantFromUser({userId: userId},
                function (applicantResult) {
                    vm.application = {};
                    vm.application.applicant = applicantResult;
                    vm.application.vacancy = vacancy;

                    Application.save(vm.application,
                        function (result) {
                            AlertService.success('Candidatura efectuada com sucesso.');
                        },
                        function (error) {
                            if (error.status == 304) {
                                AlertService.warning('Já concorreu para essa vaga.');
                            }
                            else {
                                AlertService.error('Erro ao concorrer, tente novamente ...');
                            }
                        })
                },
                function (error) {
                    AlertService.error('Erro, tente novamente ...');
                })
        }
    }
})();
