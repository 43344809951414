(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('SendApplicantEmailController', SendApplicantEmailController);

    SendApplicantEmailController.$inject = ['$uibModalInstance', 'entity', 'Applicant', '$state'];

    function SendApplicantEmailController($uibModalInstance, entity, Applicant, $state) {
        var vm = this;

        vm.applicant = entity;
        vm.clear = clear;
        vm.SendApplicantEmail = SendApplicantEmail;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function SendApplicantEmail() {

            vm.emailInfo = {};
            vm.emailInfo.email = vm.applicant.email[0];
            vm.emailInfo.subject = vm.subject;
            vm.emailInfo.message = vm.message;

            Applicant.sendEmailToApplicant(vm.emailInfo,
                function (success) {
                    $uibModalInstance.dismiss('cancel');
                },
                function (error) {

                });
        }
    }
})();
