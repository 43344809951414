(function() {
    'use strict';
    angular
        .module('hrmApp')
        .factory('City', City);

    City.$inject = ['$resource'];

    function City ($resource) {
        var resourceUrl =  'api/cities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getVacancyCities':{
                method:'GET',
                url:'api/cities/vacancies',
                isArray: true
            },
            'getVacanciesCities':{
                method:'GET',
                url:'api/all-cities-vacancies',
                isArray: true
            }
        });
    }
})();
