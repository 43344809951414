(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('VersionController', VersionController);

    VersionController.$inject = [];

    function VersionController() {

        var vm = this;
        //Version x.y.z
        //z - Minor changes/bug fixes
        //y - Big changes/features/modifications
        //x - Huge changes
        vm.version = '1.6.0';
        vm.modifications=[];
        vm.modifications.push("Miscellaneous");



    }
})();
