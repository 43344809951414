(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantApplicationController', ApplicantApplicationController);

    ApplicantApplicationController.$inject = ['AlertService', '$state', 'Application', 'Principal'];

    function ApplicantApplicationController(AlertService, $state, Application, Principal) {

        var vm = this;

        Principal.identity().then(function (account) {
            vm.account = account;
            Application.getApplicantRelated({id:account.id}, function (response) {
                //console.debug(response);
                vm.applications=response;
            })
        });


    }
})();
