(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('CvDialogController', CvDialogController);

    CvDialogController.$inject = ['Applicant', '$stateParams', '$uibModalInstance', '$http', '$uibModal', 'DataUtils', '$sce', 'Certification', 'PersonDocument'];

    function CvDialogController(Applicant, $stateParams, $uibModalInstance, $http, $uibModal, DataUtils, $sce, Certification, PersonDocument) {
        var vm = this;

        vm.clear = clear;
        vm.openFile = DataUtils.openFile;

        vm.sendEmail = function (applicant) {
            applicant.email=Applicant.getApplicantEmail({id:applicant.id});
            $uibModal.open({
                templateUrl: 'app/entities/applicant/send-applicant-email-dialog.html',
                controller: 'SendApplicantEmailController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    id: null
                },
                resolve: {
                    entity: applicant
                }
            });
        };

        vm.applicant = Applicant.getApplicant({id: $stateParams.applicantId}, function (response) {

            $http({
                method: 'GET',
                url: '/api/skills-applicant/' + $stateParams.applicantId
            }).then(function (response) {

                vm.skill = response.data[0];

            }, function errorCallback(response) {

            });

            $http({
                method: 'GET',
                url: '/api/current-date'
            }).then(function successCallback(response) {
                vm.currentDate = new Date(response.data);
            }, function () {

            });
            $http({
                method: 'GET',
                url: '/api/applicant-applications2/' + $stateParams.applicantId
            }).then(function successCallback(result) {
                vm.applications = result.data;
            }, function () {

            });
        });

        vm.calculateAge = function calculateAge(birthday) { // birthday is a date
            var brt = new Date(birthday);
            var ageDifMs = vm.currentDate - brt.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.messageActions = function () {

            vm.singleAction = {};
            var toServer = false;

            var id = vm.applicant.applicant.id;
            if (vm.action === 'edit') {
            }
            if (vm.action === 'remove') {
            }
            if (vm.action === 'note') {
                vm.singleAction.id = id;
                vm.singleAction.action = vm.action;
                vm.singleAction.message = vm.message;
                //toServer = true;
            }
            if (vm.action === 'mail') {
                vm.singleAction.id = id;
                vm.singleAction.action = vm.action;
                vm.singleAction.message = vm.message;
                toServer = true;
            }

            vm.action = '';
            vm.message = '';

            // if (toServer) {
            //     Applicant.singleApplicationAction(vm.singleAction
            //         , function (result) {
            //         }
            //         , function (error) {
            //         });
            // }
        };

        vm.multipleAction = function (action) {

            var id = vm.applicant.applicant.id;
            if (action === 'joinToVacancy') {
                var applicant = vm.applicant.applicant;
                vm.addVacancy(applicant);
            }
            if (action === 'download') {
                $http({
                    method: 'GET',
                    url: '/api/applicant-resume/' + id
                }).then(function (result) {
                    vm.applicantResume = result.data;
                    if (vm.applicantResume.file !== null) {
                        vm.openFile = DataUtils.openFile;
                        vm.openFile(vm.applicantResume.fileContentType, vm.applicantResume.file)
                    }
                });
            }

        };

        vm.addVacancy = function (applicant) {
            $uibModal.open({
                templateUrl: 'app/entities/application/applicant-application-dialog.html',
                controller: 'ApplicantApplicationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('application');
                        $translatePartialLoader.addPart('applicationSelected');
                        $translatePartialLoader.addPart('applicationStages');
                        $translatePartialLoader.addPart('swalMessages');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            created: null,
                            selected: null,
                            approved: null,
                            notes: null,
                            stages: null,
                            id: null,
                            applicant: applicant
                        };
                    }
                }
            }).result.then(function () {
                //$state.go('vacancy-detail', {}, {reload: false});
            }, function () {
                //$state.go('vacancy-detail');
            });
        };

        vm.downloadCV = function () {
            vm.disablePdf = true;
            $http({
                method: 'GET',
                url: '/api/applicant-pdf/' + $stateParams.applicantId,
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disablePdf = false;
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'Curriculum-Vitae #' + $stateParams.applicantId + '.pdf');
                downloadLink[0].click();
            }, function (response) {
                vm.disablePdf = false;
            });
        };


        //-----------------------------
        //Anexos
        $http({
            method: 'GET',
            url: '/api/certifications-applicant/' + $stateParams.applicantId
        }).then(function (response) {
            vm.certifications = response.data;
        }, function errorCallback(response) {

        });

        $http({
            method: 'GET',
            url: '/api/person-documents-applicant/' + $stateParams.applicantId
        }).then(function (response) {
            vm.personDocuments = response.data;
        }, function errorCallback(response) {

        });


        vm.openEntityFile = function (entity, type) {
            vm.isLoadingFile = true;

            if (type === 'CERT') {
                Certification.get({id: entity.id}, function (result) {
                    if (result.file) {
                        vm.openFile(result.fileContentType, result.file);
                        vm.isLoadingFile = false;
                    } else {
                        fileNotFound();
                    }
                });
            }
            if (type === 'PD') {
                PersonDocument.get({id: entity.id}, function (result) {
                    if (result.file) {
                        vm.openFile(result.fileContentType, result.file);
                        vm.isLoadingFile = false;
                    } else {
                        fileNotFound();
                    }
                });
            }
        };

        function fileNotFound() {
            swal({
                title: '',
                text: vm.fileNotFound,
                type: "warning"
            });
            vm.isLoadingFile = false;
        }
    }
})();
