(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('InternshipController', InternshipController);

    InternshipController.$inject = ['Internship'];

    function InternshipController(Internship) {

        var vm = this;

        vm.internship=Internship.get({id:1});
    }
})();
