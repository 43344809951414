(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantController', ApplicantController);

    ApplicantController.$inject = ['pagingParams', 'paginationConstants', 'ParseLinks', 'AlertService', '$state', '$http', 'Applicant', '$uibModal'];

    function ApplicantController(pagingParams, paginationConstants, ParseLinks, AlertService, $state, $http, Applicant, $uibModal) {

        var vm = this;
        vm.spinner = true;
        getDate();
        vm.listing = true;
        vm.toggleListing = function () {
            vm.listing = !vm.listing;
        };
        /*
        vm.applicants = Applicant.getAllApplicantsHome(function () {
            vm.spinner = false;
        }, function () {
            vm.spinner = false;
        });
*/
        vm.search = function (query) {
            if(!query){
                loadAll();
            }else{
                Applicant.search({
                    query:query,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                }, onSuccess, onError);
            }
        };

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        function loadAll () {
            Applicant.getAllApplicantsHome({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

        }
        function onSuccess(data, headers) {
            vm.spinner = false;
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.applicants = data;
            vm.page = pagingParams.page;
            loadImage(0);
        }
        function onError(error) {
            vm.spinner = false;
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        /*
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        function loadAll () {
            Applicant.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.applicants = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }*/

        function getDate() {
            $http({
                method: 'GET',
                url: '/api/current-date'
            }).then(function successCallback(response) {
                vm.currentDate = new Date(response.data);
            }, function () {

            });
        }


        vm.calculateAge = function calculateAge(birthday) { // birthday is a date
            var brt = new Date(birthday);
            var ageDifMs = vm.currentDate - brt.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };


        vm.addVacancy = function (applicant) {
            $uibModal.open({
                templateUrl: 'app/entities/application/applicant-application-dialog.html',
                controller: 'ApplicantApplicationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('application');
                        $translatePartialLoader.addPart('applicationSelected');
                        $translatePartialLoader.addPart('applicationStages');
                        $translatePartialLoader.addPart('swalMessages');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            created: null,
                            selected: null,
                            approved: null,
                            notes: null,
                            stages: null,
                            id: null,
                            applicant: applicant
                        };
                    }
                }
            }).result.then(function () {
                //$state.go('vacancy-detail', {}, {reload: false});
            }, function () {
               //$state.go('vacancy-detail');
            });
        };

        vm.sendEmail = function (applicant) {
            applicant.email=Applicant.getApplicantEmail({id:applicant.id});
            $uibModal.open({
                templateUrl: 'app/entities/applicant/send-applicant-email-dialog.html',
                controller: 'SendApplicantEmailController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    id: null
                },
                resolve: {
                    entity: applicant
                }
            });
        };



        function loadImage(index){
            var id=vm.applicants[index].id;


            Applicant.findOne({id:id}, function (response) {
                vm.applicants[index].image=response.image;
                vm.applicants[index].imageContentType=response.imageContentType;
                if(index!==vm.applicants.length-1){
                    loadImage(index+1);
                }
            })


        }


    }
})();
