(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('CertificationDeleteController',CertificationDeleteController);

    CertificationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Certification'];

    function CertificationDeleteController($uibModalInstance, entity, Certification) {
        var vm = this;

        vm.certification = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Certification.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
