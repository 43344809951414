(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ObservationController', ObservationController);

    ObservationController.$inject = ['Observation'];

    function ObservationController(Observation) {

        var vm = this;

        vm.observations = [];

        loadAll();

        function loadAll() {
            Observation.query(function(result) {
                vm.observations = result;
                vm.searchQuery = null;

                //console.debug(vm.observations);
            });
        }
    }
})();
