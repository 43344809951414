(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('HomeInternshipController', HomeInternshipController);

    HomeInternshipController.$inject = ['Internship', 'Vacancy'];

    function HomeInternshipController(Internship, Vacancy) {

        var vm = this;

        vm.internship = Internship.get({id:1});
        vm.vacancies=Vacancy.getInternship();
    }
})();
