(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('SendMultipleApplicantEmailController', SendMultipleApplicantEmailController);

    SendMultipleApplicantEmailController.$inject = ['$uibModalInstance', 'Applicant', '$state', '$stateParams'];

    function SendMultipleApplicantEmailController($uibModalInstance, Applicant, $state, $stateParams) {
        var vm = this;
        vm.spinner=false;
        vm.applicants = $state.params.applicants;

        vm.clear = clear;
        vm.SendApplicantEmail = SendApplicantEmail;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function SendApplicantEmail() {
            vm.spinner=true;
            angular.forEach(vm.applicants, function(value, key) {

                vm.emailInfo = {};
                vm.emailInfo.email = value.email;
                vm.emailInfo.subject = vm.subject;
                vm.emailInfo.message = vm.message;

                Applicant.sendEmailToApplicant(vm.emailInfo,
                    function (success) {
                        $uibModalInstance.dismiss('cancel');
                        vm.spinner=false;
                    },
                    function (error) {
                        vm.spinner=false;
                    });
            });

        }
    }
})();
