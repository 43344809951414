(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('VacancyInfoController', VacancyInfoController);

    VacancyInfoController.$inject = ['$state', '$anchorScroll', 'previousState', 'entity', 'DataUtils', 'RequirementFilter', '$translate', 'Vacancy', 'AlertService'];

    function VacancyInfoController($state, $anchorScroll, previousState, entity, DataUtils, RequirementFilter, $translate, Vacancy, AlertService) {
        var vm = this;
        vm.isAuthenticated = false;
        vm.vacancy = entity;
        vm.previousState = previousState.name;
        vm.tabNames =
        {'tab1':0, 'tab2':1, 'tab3':2, 'tab4':3, 'tab5':4, 'tab6':5 };
        vm.activeTab = vm.tabNames.tab1;
        vm.openFile = DataUtils.openFile;
        $anchorScroll();

        vm.requirementFilter = RequirementFilter.get({id:vm.vacancy.requirementFilter.id});
        vm.finish = function (vacancy) {
            $translate('hrmApp.swalMessages.yes').then(function (translation) {
                vm.yes = translation;
                $translate('hrmApp.swalMessages.no').then(function (translation) {
                    vm.no = translation;
                    $translate('hrmApp.swalMessages.finalizeVacancy').then(function (translation) {
                        swal({
                            title: '',
                            text: translation,
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: vm.yes,
                            cancelButtonText: vm.no,
                            closeOnConfirm: true,
                            closeOnCancel: true
                        }, function (isConfirm) {
                            if (isConfirm) {
                                updateVacancy(vacancy, 'FINALIZED');
                            }
                        })
                    });
                });
            });

            function updateVacancy(vacancy, status) {
                Vacancy.get({id: vacancy.id}, function (vc) {
                    vc.vacancyStatus = status;
                    vc.status = status;
                    Vacancy.update(vc, function (result) {
                        $state.go('vacancy');
                    }, function (result) {
                        AlertService.error("Erro");
                    })
                });

            }


        };
    }
})();
