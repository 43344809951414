(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('RequirementFilterController', RequirementFilterController);

    RequirementFilterController.$inject = ['RequirementFilter'];

    function RequirementFilterController(RequirementFilter) {

        var vm = this;

        vm.requirementFilters = [];

        loadAll();

        function loadAll() {
            RequirementFilter.query(function(result) {
                vm.requirementFilters = result;
                vm.searchQuery = null;
            });
        }
    }
})();
