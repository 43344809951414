(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ObservationDialogModalController', ObservationDialogModalController);

    ObservationDialogModalController.$inject = ['$timeout', '$state', '$uibModalInstance', 'AlertService', 'Application'];

    function ObservationDialogModalController($timeout, $state, $uibModalInstance, AlertService, Application) {
        var vm = this;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.placeObservation = placeObservation;

        vm.action = $state.params.action;
        vm.ids = $state.params.ids;
        vm.filterStage = $state.params.filterStage;


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function placeObservation() {

            vm.multipleAction = {};
            vm.multipleAction.action = vm.action;
            vm.multipleAction.ids = vm.ids;
            vm.multipleAction.observation = vm.observation;
            vm.multipleAction.stage = vm.filterStage;

            Application.applicationMultipleAction(vm.multipleAction
                , function (result) {
                    $uibModalInstance.close(result);
                }
                , function (error) {
                    AlertService.warning('Error');
                });
        }

    }
})();
