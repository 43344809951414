(function () {
    'use strict';

    angular
        .module('hrmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('application', {
                parent: 'entity',
                url: '/application',
                data: {
                    authorities: ['ROLE_RH_MANAGER','ROLE_RH_TECHNICIAN'],
                    pageTitle: 'hrmApp.application.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/application/applications.html',
                        controller: 'ApplicationController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('application');
                        $translatePartialLoader.addPart('applicationSelected');
                        $translatePartialLoader.addPart('applicationStages');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).state('user-applications', {
                parent: 'entity',
                url: '/my-applications',
                data: {
                    authorities: ['ROLE_APPLICANT'],
                    pageTitle: 'hrmApp.application.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/application/applicant-applications.html',
                        controller: 'ApplicantApplicationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('application');
                        $translatePartialLoader.addPart('applicationSelected');
                        $translatePartialLoader.addPart('applicationStages');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('previousJob');
                        return $translate.refresh();
                    }]
                }
            })
            .state('application-detail', {
                parent: 'application',
                url: '/application/{id}',
                data: {
                    authorities: ['ROLE_RH_MANAGER','ROLE_RH_TECHNICIAN'],
                    pageTitle: 'hrmApp.application.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/application/application-detail.html',
                        controller: 'ApplicationDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('application');
                        $translatePartialLoader.addPart('applicationSelected');
                        $translatePartialLoader.addPart('applicationStages');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Application', function ($stateParams, Application) {
                        return Application.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'application',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('application-detail.edit', {
                parent: 'application-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_RH_MANAGER','ROLE_RH_TECHNICIAN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/application/application-dialog.html',
                        controller: 'ApplicationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Application', function (Application) {
                                return Application.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('application.new', {
                parent: 'application',
                url: '/new',
                data: {
                    authorities: ['ROLE_RH_MANAGER','ROLE_RH_TECHNICIAN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/application/application-dialog.html',
                        controller: 'ApplicationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    created: null,
                                    selected: null,
                                    approved: null,
                                    notes: null,
                                    stages: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('application', null, {reload: 'application'});
                    }, function () {
                        $state.go('application');
                    });
                }]
            })
            .state('application.edit', {
                parent: 'application',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_RH_MANAGER','ROLE_RH_TECHNICIAN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/application/application-dialog.html',
                        controller: 'ApplicationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Application', function (Application) {
                                return Application.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('application', null, {reload: 'application'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('application.delete', {
                parent: 'application',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_RH_MANAGER','ROLE_RH_TECHNICIAN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/application/application-delete-dialog.html',
                        controller: 'ApplicationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Application', function (Application) {
                                return Application.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('application', null, {reload: 'application'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            }).state('user-applications.cancel', {
                parent: 'user-applications',
                url: '/{id}/cancel',
                data: {
                    authorities: ['ROLE_APPLICANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/application/application-delete-dialog.html',
                        controller: 'ApplicationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Application', function (Application) {
                                return Application.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('user-applications', null, {reload: 'user-applications'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('vacancy-detail.remove-application', {
                parent: 'vacancy-detail',
                url: '/remove-application/{applicationId}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/application/remove-application-dialog.html',
                        controller: 'RemoveApplicationDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['Application', function (Application) {
                                //console.debug($stateParams);
                                return Application.get({id: $stateParams.applicationId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('vacancy-detail', null, {reload: 'vacancy-detail'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('vacancy-detail.advance-application', {
                parent: 'vacancy-detail',
                url: '/{id}/advance-application/{applicationId}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/application/move-application-to-next-stage-dialog.html',
                        controller: 'MoveApplicationToNextStageDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['Application', function (Application) {
                                return Application.get({id: $stateParams.applicationId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('vacancy-detail', null, {reload: 'vacancy-detail'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
