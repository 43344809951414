(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('AboutController', AboutController);

    AboutController.$inject = [];

    function AboutController() {

        var vm = this;


    }
})();
