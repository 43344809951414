(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('VacancyController', VacancyController);

    VacancyController.$inject = ['pagingParams', 'paginationConstants', 'ParseLinks', 'AlertService', '$state', 'Vacancy', 'Principal', '$sce', '$http', '$rootScope', '$translate', 'City'];

    function VacancyController(pagingParams, paginationConstants, ParseLinks, AlertService, $state, Vacancy, Principal, $sce, $http, $rootScope, $translate, City) {

        var vm = this;
        vm.isManager = false;
        vm.isTechnician = false;
        vm.vacancies = [];
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.disableExcel = false;
        vm.disablePdf = false;
        vm.advancedSearch = false;
        vm.spinner = true;
        vm.cities = City.getVacanciesCities();

        Principal.identity().then(function (account) {
            vm.account = account;

            var ready = false;
            if (vm.account.authorities.indexOf('ROLE_RH_MANAGER') != -1 || vm.account.authorities.indexOf('ROLE_ADMIN') != -1) {
                vm.isManager = true;
                vm.userId = vm.account.id;
                ready = true;
            }
            if (vm.account.authorities.indexOf('ROLE_RH_TECHNICIAN') != -1) {
                vm.isTechnician = true;
                vm.userId = vm.account.id;
                ready = true;
            }

            if (ready) {
                vm.loadAll();
            }
        });

        /*
         function loadAll() {

         if (vm.isTechnician) {
         Vacancy.queryUserRelated({
         userId: vm.userId,
         page: pagingParams.page - 1,
         size: paginationConstants.itemsPerPage,
         sort: sort()
         }, onSuccess, onError);
         }
         if(vm.isManager){
         Vacancy.query({
         page: pagingParams.page - 1,
         size: paginationConstants.itemsPerPage,
         sort: sort()
         }, onSuccess, onError);
         }
         function sort() {
         var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
         if (vm.predicate !== 'id') {
         result.push('id');
         }
         return result;
         }

         function onSuccess(data, headers) {
         vm.links = ParseLinks.parse(headers('link'));
         vm.totalItems = headers('X-Total-Count');
         vm.queryCount = vm.totalItems;
         vm.vacancies = data;
         vm.page = pagingParams.page;

         if(vm.vacancies.length > 0){
         vm.disableExcel = false;
         vm.disablePdf = false;
         }
         else{
         vm.disableExcel = true;
         vm.disablePdf = true;
         }
         }

         function onError(error) {
         AlertService.error(error.data.message);
         }
         }

         function loadPage(page) {
         vm.page = page;
         vm.transition();
         }

         function transition() {
         $state.transitionTo($state.$current, {
         page: vm.page,
         sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
         search: vm.currentSearch
         });
         }
         */
        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.activate = function (vacancy) {
            updateVacancy(vacancy, 'ACTIVE');
            $rootScope.$broadcast('updateCount');
        };

        function updateVacancy(vacancy, status) {
            Vacancy.get({id: vacancy.id}, function (vc) {
                //console.debug("VC");

                vc.vacancyStatus = status;
                vc.status = status;
                //console.debug(vc);
                Vacancy.update(vc, function (result) {
                    vm.loadAll();
                }, function (result) {
                    AlertService.error("Erro");
                })
            });

        }

        vm.deactivate = function (vacancy) {
            updateVacancy(vacancy, 'INACTIVE');
        };


        vm.vacancyReportPDF = function () {
            vm.disablePdf = true;
            $http({
                method: 'GET',
                url: '/api/vacancy-report-pdf',
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disablePdf = false;
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'vagas-HRM.pdf');
                downloadLink[0].click();
            }, function (response) {
                vm.disablePdf = false;
            });
        };

        vm.vacancyReportExcel = function () {
            vm.disableExcel = true;

            $http({
                method: 'GET',
                url: '/api/vacancy-report-excel',
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disableExcel = false;
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'vagas-HRM.xls');
                downloadLink[0].click();
            }, function (response) {
                vm.disableExcel = false;
            });
        };

        vm.downloadPDF = function () {
            vm.disablePdf = true;
            $http({
                method: 'GET',
                url: '/api/vacancies-pdf/',
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disablePdf = false;
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'vagas-HRM.pdf');
                downloadLink[0].click();
            }, function (response) {

            });
        };


        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();


        function loadAll() {
            vm.spinner = true;

            if (vm.isTechnician) {
                Vacancy.queryHomeUserRelated({
                    userId: vm.userId,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            if (vm.isManager) {
                Vacancy.queryHome({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }


            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vacancies = data;
                vm.page = pagingParams.page;
                vm.spinner = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.spinner = false;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        /*
         vm.changeAdvancedSearch = function () {
         if (vm.advancedSearch) {
         vm.advancedSearch = false;
         }
         else {
         vm.advancedSearch = true;
         }
         };*/

        vm.search = function () {

            var changeSearchParam = false;
            var changeFromDate = false;
            var changeToDate = false;
            var city;
            if (vm.searchParam === undefined || vm.searchParam === '') {
                vm.searchParam = 'null';
                changeSearchParam = true;
            }
            if (vm.status === undefined || vm.status === '') {
                vm.status = 'null';
            }

            if (vm.city === undefined || vm.city === '') {
                city = 'null';
            }
            else {
                city = vm.city.name;
            }

            if (vm.fromDate === undefined || vm.fromDate === '') {
                vm.fromDate = 'null';
                changeFromDate = true;
            }

            if (vm.toDate === undefined || vm.toDate === '') {
                vm.toDate = 'null';
                changeToDate = true;
            }

            /*
            console.debug(vm.searchParam);
            console.debug(vm.status);
            console.debug(city);
            console.debug(vm.fromDate);
            console.debug(vm.toDate);*/

            vm.spinner = true;

            Vacancy.adminSearchVacancy({
                searchParam: vm.searchParam,
                status: vm.status,
                city: city,
                fromDate: vm.fromDate,
                toDate: vm.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vacancies = data;
                vm.page = pagingParams.page;

                if (changeSearchParam) {
                    vm.searchParam = '';
                }
                if (changeFromDate) {
                    vm.fromDate = '';
                }
                if (changeToDate) {
                    vm.toDate = '';
                }
                vm.spinner = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                if (changeSearchParam) {
                    vm.searchParam = '';
                }
                vm.spinner = false;
            }
        }
    }
})();
