(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('PersonDocumentDeleteController',PersonDocumentDeleteController);

    PersonDocumentDeleteController.$inject = ['$uibModalInstance', 'entity', 'PersonDocument'];

    function PersonDocumentDeleteController($uibModalInstance, entity, PersonDocument) {
        var vm = this;

        vm.personDocument = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PersonDocument.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
