(function() {
    'use strict';
    angular
        .module('hrmApp')
        .factory('PreviousJob', PreviousJob);

    PreviousJob.$inject = ['$resource', 'DateUtils'];

    function PreviousJob ($resource, DateUtils) {
        var resourceUrl =  'api/previous-jobs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fromDate = DateUtils.convertDateTimeFromServer(data.fromDate);
                        data.toDate = DateUtils.convertDateTimeFromServer(data.toDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getPreviousJobsInstitutes':{
                url: 'api/previous-jobs-institutes',
                method: 'GET',
                isArray: true
            },
            'getPreviousJobsPositions':{
                url: 'api/previous-jobs-positions',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
