(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantObservationController', ApplicantObservationController);

    ApplicantObservationController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Observation', 'Application'];

    function ApplicantObservationController($scope, $rootScope, $stateParams, previousState, entity, Observation, Application) {
        var vm = this;

        vm.observations = entity;

        if(vm.observations.length > 0){
            vm.applicant = vm.observations[0].application.applicant;
        }

        //console.debug(vm.observations);

        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('hrmApp:observationUpdate', function(event, result) {
            vm.observation = result;

        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
