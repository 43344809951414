(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('PersonDocumentController', PersonDocumentController);

    PersonDocumentController.$inject = ['DataUtils', 'PersonDocument'];

    function PersonDocumentController(DataUtils, PersonDocument) {

        var vm = this;

        vm.personDocuments = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            PersonDocument.query(function(result) {
                vm.personDocuments = result;
                vm.searchQuery = null;
            });
        }
    }
})();
