(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('CompanyController', CompanyController);

    CompanyController.$inject = ['DataUtils', 'Company'];

    function CompanyController(DataUtils, Company) {

        var vm = this;

        vm.companies = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            Company.query(function(result) {
                vm.companies = result;
                vm.searchQuery = null;
            });
        }
    }
})();
