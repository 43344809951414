(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('RequirementFilterDeleteController',RequirementFilterDeleteController);

    RequirementFilterDeleteController.$inject = ['$uibModalInstance', 'entity', 'RequirementFilter'];

    function RequirementFilterDeleteController($uibModalInstance, entity, RequirementFilter) {
        var vm = this;

        vm.requirementFilter = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RequirementFilter.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
