(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('PreviousJobController', PreviousJobController);

    PreviousJobController.$inject = ['PreviousJob'];

    function PreviousJobController(PreviousJob) {

        var vm = this;

        vm.previousJobs = [];

        loadAll();

        function loadAll() {
            PreviousJob.query(function(result) {
                vm.previousJobs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
