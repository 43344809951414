(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantApplicationDialogController', ApplicantApplicationDialogController);

    ApplicantApplicationDialogController.$inject = ['$timeout', '$scope', '$http', '$uibModalInstance', 'entity', '$translate', 'Vacancy', 'Applicant'];

    function ApplicantApplicationDialogController ($timeout, $scope, $http, $uibModalInstance, entity, $translate, Vacancy, Applicant) {
        var vm = this;

        vm.application = entity;
        vm.clear = clear;
        vm.save = save;
        vm.vacancies = Vacancy.getVacanciesForApplicant({id:vm.application.applicant.id});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            $http({
                method: 'POST',
                url: '/api/application-for-applicant/save-multiple',
                data: vm.application
            }).then(onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('hrmApp:applicationUpdate', result);
            $translate('hrmApp.swalMessages.vacancySavedSuccess').then(function (translation) {
                swal(translation, " ", "success");
            });
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
