(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('LanguageController', LanguageController);

    LanguageController.$inject = ['Language'];

    function LanguageController(Language) {

        var vm = this;

        vm.languages = [];

        loadAll();

        function loadAll() {
            Language.query(function(result) {
                vm.languages = result;
                vm.searchQuery = null;
            });
        }
    }
})();
