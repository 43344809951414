(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('InternshipDialogController', InternshipDialogController);

    InternshipDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Internship'];

    function InternshipDialogController ($timeout, $scope, $state, $stateParams, entity, Internship) {
        var vm = this;

        vm.internship = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('internship');
        }

        function save () {
            vm.isSaving = true;
            if (vm.internship.id !== null) {
                Internship.update(vm.internship, onSaveSuccess, onSaveError);
            } else {
                Internship.save(vm.internship, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('hrmApp:internshipUpdate', result);
            $state.go('internship');
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
