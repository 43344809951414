(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('VacancyDetailController', VacancyDetailController);

    VacancyDetailController.$inject = ['$scope', '$rootScope', '$uibModal', 'previousState', 'entity', 'Vacancy', '$state', '$sce', 'Application', 'AlertService', '$http', '$translate', 'City', 'Degree', 'Language', 'PreviousJob', 'Applicant'];

    function VacancyDetailController($scope, $rootScope, $uibModal, previousState, entity, Vacancy, $state, $sce, Application, AlertService, $http, $translate, City, Degree, Language, PreviousJob, Applicant) {
        var vm = this;
        getDate();
        vm.vacancy = entity;
        vm.previousState = previousState.name;
        vm.jobInstitutions = PreviousJob.getPreviousJobsInstitutes();
        vm.positions = PreviousJob.getPreviousJobsPositions();
        vm.disablePdf = true;
        vm.disableMultiple = true;
        vm.disableDownloadCSV = true;
        vm.disableNextPhase = false;
        vm.disablePreviousPhase = true;
        vm.showDetails = false;
        vm.showFilters = false;
        vm.clearFilters = false;
        vm.backupApplications = {};
        vm.gndr = [];
        vm.gndr[0] = 'MALE';
        vm.gndr[1] = 'FEMALE';
        vm.filterStage = null;
        vm.action = null;
        vm.stage = null;
        load();
        disableStyle();

        vm.selectedStyle = {
            "color": "white",
            "background-color": "#f0ad4e",
            "width": "100%"
        };

        vm.maritalStatus = ['CASADO', 'SOLTEIRO', 'VIUVO', 'DIVORCIADO'];

        vm.removeFilter = function (param) {
            if (param === 'personInfo') {
                vm.filter.gender = null;
                vm.filter.maritalStatus = null;
                vm.filterMinAge = null;
                vm.filterMaxAge = null;
                vm.filter.nuit = null;
                vm.filter.hobbies = null;
            }
            if (param === 'contacts') {
                vm.filter.emails = null;
                vm.filter.contacts = null;
            }
            if (param === 'address') {
                vm.filter.city = null;
            }
            if (param === 'academicInfo') {
                vm.filter.academicDegrees = null;
                vm.filter.academicLevels = null;
                vm.filter.academicInstitution = null;
                vm.filter.languages = null;
            }
            if (param === 'professionalInfo') {
                vm.filter.position = null;
                vm.filter.company = null;
                vm.minFilterExperienceYears = null;
                vm.maxFilterExperienceYears = null;
            }
        };

        vm.minAge = 10;
        vm.maxAge = 100;

        vm.minFilterExperienceYears = 0;
        vm.maxFilterExperienceYears = 15;
        vm.filterMinAge = 18;
        vm.filterMaxAge = 35;

        vm.toggleStyle = function (param) {
            disableStyle();
            vm.selected = param;
            if (param === 'contact') {
                vm.contactStyle = vm.selectedStyle;
            }
            if (param === 'perInfo') {
                vm.perInfoStyle = vm.selectedStyle
            }
            if (param === 'address') {
                vm.addressStyle = vm.selectedStyle
            }
            if (param === 'academic') {
                vm.academicStyle = vm.selectedStyle
            }
            if (param === 'job') {
                vm.jobStyle = vm.selectedStyle
            }
        };

        vm.toggleStyle('perInfo');

        function disableStyle() {
            vm.contactStyle = {"width": "100%"};
            vm.perInfoStyle = {"width": "100%"};
            vm.addressStyle = {"width": "100%"};
            vm.academicStyle = {"width": "100%"};
            vm.jobStyle = {"width": "100%"};
        }

        vm.toggleDetails = function () {
            vm.showDetails = !vm.showDetails;
        };

        vm.showHideFilters = function () {
            if (vm.showFilters) {
                vm.showFilters = false;
            } else {
                vm.cities = City.query({size: 999999});
                vm.degrees = Degree.query({size: 999999});
                vm.languages = Language.query({size: 999999});
                vm.showFilters = true;
            }
        };

        function getDate() {
            $http({
                method: 'GET',
                url: '/api/current-date'
            }).then(function successCallback(response) {
                vm.currentDate = new Date(response.data);
            }, function () {

            });
        }

        vm.searchFilter = function () {

            if (vm.searchApplicant !== undefined) {
                var text = vm.searchApplicant.toString().toLowerCase();

                var applicantIndex = 0;

                if (text.length > 2) {
                    vm.applications = [];
                    for (var i = 0; i < vm.backupApplications.length; i++) {
                        var applicant = vm.backupApplications[i];
                        if (applicant.name.toLowerCase().indexOf(text) !== -1) {
                            vm.applications[applicantIndex] = applicant;
                            applicantIndex++;
                        }
                    }
                }
                else {
                    vm.applications = vm.backupApplications;
                }
                vm.countFiltered = vm.countType(vm.stage);
            }

        };

        vm.sendEmails = function () {
            vm.checkedAppcts = [];
            for (var i = 0; i < vm.applications.length; i++) {
                if (vm.applications[i].checked) {
                    vm.checkedAppcts.push(vm.applications[i]);
                }
            }
            $state.go('vacancy.send-email', {applicants: vm.checkedAppcts});
        };

        vm.calculateAge = function calculateAge(birthday) { // birthday is a date
            var brt = new Date(birthday);
            var ageDifMs = vm.currentDate - brt.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };

        function load() {
            vm.applications = Application.getVacancyApplications({vacancyId: vm.vacancy.id}
                , function (result) {
                    vm.backupApplications = result;

                    if (result.length > 0) {
                        loadImage(0);
                        vm.disablePdf = false;
                        /*for (var i = 0; i < result.length; i++) {
                         vm.countFilter += 1;
                         vm.countFilterTotal += 1;
                         }*/
                    }
                    else {
                        vm.disablePdf = true;
                    }
                },
                function (error) {
                    vm.disablePdf = true;
                });
        }

        $scope.$on('application-set', function (event, args) {
            load();
        });

        var unsubscribe = $rootScope.$on('hrmApp:vacancyUpdate', function (event, result) {
            vm.vacancy = result;
        });
        $scope.$on('$destroy', unsubscribe);
        vm.tabNames =
            {'tab1': 0, 'tab2': 1, 'tab3': 2, 'tab4': 3, 'tab5': 4, 'tab6': 5, 'tab7': 6};
        vm.activeTab = vm.tabNames.tab1;

        vm.addApplicant = function () {
            $uibModal.open({
                templateUrl: 'app/entities/application/vacancy-application-dialog.html',
                controller: 'VacancyApplicationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('application');
                        $translatePartialLoader.addPart('applicationSelected');
                        $translatePartialLoader.addPart('applicationStages');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            created: null,
                            selected: null,
                            approved: null,
                            notes: null,
                            stages: null,
                            id: null,
                            vacancy: vm.vacancy
                        };
                    }
                }
            }).result.then(function () {
                $state.go('vacancy-detail', {}, {reload: false});
            }, function () {
                $state.go('vacancy-detail');
            });
        };

        vm.manuallyTriggerVacancyFilter = function () {

            Vacancy.manualVacancyFilter(vm.vacancy
                , function (res) {

                    if (res.done) {
                        $translate('hrmApp.swalMessages.screenedSuccessfully').then(function (translation) {
                            swal({
                                title: "Sucesso!",
                                text: res.number + translation,
                                type: "success",
                                confirmButtonText: "OK"
                            });
                        });
                        $rootScope.$broadcast('updateCount');
                        $state.go('vacancy-detail', {id: vm.vacancy.id}, {reload: true});
                    }
                    else {
                        $translate('hrmApp.swalMessages.screenedError').then(function (translation) {
                            swal({
                                title: "Warning!",
                                text: translation,
                                type: "warning",
                                confirmButtonText: "OK"
                            });
                        });
                        $rootScope.$broadcast('updateCount');
                    }
                },
                function (error) {
                });

        };

        vm.vacancyApplicantReport = function (vacancyID) {

            vm.disablePdf = true;
            $http({
                method: 'GET',
                url: '/api/vacancy-applicant-report/' + vacancyID,
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disablePdf = false;
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'Candidatos-da-Vaga.pdf');
                downloadLink[0].click();
            }, function (response) {
                vm.disablePdf = false;
            });
        };

        vm.countType = function (type) {
            var counter = 0;
            for (var i = 0; i < vm.applications.length; i++) {
                var application = vm.applications[i];
                if (application.stages === type) {
                    counter = counter + 1;
                }
            }
            return counter;
        };

        vm.countTotal = function (type) {
            var counter = 0;
            for (var i = 0; i < vm.backupApplications.length; i++) {
                var application = vm.backupApplications[i];
                if (application.stages === type) {
                    counter = counter + 1;
                }
            }
            return counter;
        };

        vm.changeStage = function (stage) {
            ids = [];
            index = 0;
            if (stage === undefined) {
                vm.filterStage = null;
                vm.disablePreviousPhase = true;
                vm.disableNextPhase = false;
                vm.countFiltered = vm.countType();
            }
            else {
                vm.disablePreviousPhase = false;
                if (stage !== 'FIFTH') {
                    vm.disableNextPhase = false;
                }
                else {
                    vm.disableNextPhase = true;
                }
                vm.filterStage = stage;
            }
            vm.countFiltered = vm.countType(stage);
            vm.countFilterTotal = vm.countTotal(stage);

            vm.stage = stage;
        };

        var ids = [];
        var index = 0;

        vm.selectedApplications = function (id, checked) {
            if (checked) {
                ids[index] = id;
                index += 1;
                vm.disableMultiple = false;
                vm.disableDownloadCSV = false;
            }
            else {
                for (var i = 0; i < ids.length; i++) {
                    if (ids[i] === id) {
                        ids.splice(i, 1);
                        index -= 1;
                        if (index <= 0) {
                            vm.disableMultiple = true;
                            vm.disableDownloadCSV = true;
                        }
                    }
                }
            }
        };

        vm.multipleAction = function (action) {

            $state.go('vacancy-detail.observation-modal',
                {
                    action: action,
                    ids: ids,
                    stage: vm.filterStage
                });
        };

        vm.downloadCSV = function () {

            var requestIds = String(ids);

            $http({
                method: 'GET',
                url: '/api/applicants-csv-download/' + requestIds,
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disablePdf = false;
                var file = new Blob([response.data], {type: 'text/csv'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'CandidatosDaVaga.csv');
                downloadLink[0].click();
            }, function (response) {
                vm.disablePdf = false;
            });

        };

        vm.filterAction = function () {

            //console.debug(vm.stage);

            vm.gender = [];

            vm.filterModel = {};

            vm.filterModel.vacancyId = vm.vacancy.id;
            vm.filterModel.applicationStage = '';
            vm.filterModel.minAge = vm.filterMinAge;
            vm.filterModel.maxAge = vm.filterMaxAge;

            vm.filterModel.position = vm.filter.position;
            vm.filterModel.company = vm.filter.company;
            vm.filterModel.minExperienceYears = vm.minFilterExperienceYears;
            vm.filterModel.maxExperienceYears = vm.maxFilterExperienceYears;

            vm.filterModel.degrees = vm.filter.academicDegrees;
            vm.filterModel.academicLevels = vm.filter.academicLevels;
            vm.filterModel.academicInstitution = vm.filter.academicInstitution;
            vm.filterModel.languages = vm.filter.languages;

            vm.filterModel.maritalStatus = vm.filter.maritalStatus;
            vm.filterModel.gender = vm.filter.gender;

            vm.filterModel.emails = vm.filter.emails;
            vm.filterModel.contacts = vm.filter.contacts;

            vm.filterModel.cities = vm.filter.city;

            vm.filterModel.abilities = vm.filter.abilities;
            vm.filterModel.certifications = vm.filter.certifications;


            Application.applicantFilterModel(vm.filterModel
                , function (result) {
                    vm.applications = result;
                    vm.clearFilters = false;

                    vm.countFiltered = vm.countType(vm.stage);
                }
                , function (error) {

                });
        };

        vm.resetFilters = function () {
            vm.applications = vm.backupApplications;
            vm.clearFilters = true;

            vm.countFiltered = vm.countType()

        };

        vm.selectAll = function (checked, stage) {

            ids = [];
            index = 0;

            for (var i = 0; i < vm.applications.length; i++) {
                var application = vm.applications[i];

                if (application.stages === stage) {
                    if (checked) {
                        application.checked = true;
                        ids[index] = application.id;
                        index += 1;
                        vm.disableMultiple = false;
                        vm.disableDownloadCSV = false;
                    }
                    else {
                        application.checked = false;
                        vm.disableMultiple = true;
                        vm.disableDownloadCSV = true;
                    }
                }
            }
        };

        vm.finish = function (vacancy) {
            $translate('hrmApp.swalMessages.yes').then(function (translation) {
                vm.yes = translation;
                $translate('hrmApp.swalMessages.no').then(function (translation) {
                    vm.no = translation;
                    $translate('hrmApp.swalMessages.finalizeVacancy').then(function (translation) {
                        swal({
                            title: '',
                            text: translation,
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: vm.yes,
                            cancelButtonText: vm.no,
                            closeOnConfirm: true,
                            closeOnCancel: true
                        }, function (isConfirm) {
                            if (isConfirm) {
                                updateVacancy(vacancy, 'FINALIZED');
                            }
                        })
                    });
                });
            });

            function updateVacancy(vacancy, status) {
                Vacancy.get({id: vacancy.id}, function (vc) {
                    vc.vacancyStatus = status;
                    vc.status = status;
                    Vacancy.update(vc, function (result) {
                        $state.go('vacancy');
                    }, function (result) {
                        AlertService.error("Erro");
                    })
                });

            }


        };


        function loadImage(index) {
            var id = vm.applications[index].applicantId;
            Applicant.findOne({id: id}, function (response) {
                vm.applications[index].image = response.image;
                vm.applications[index].imageContentType = response.imageContentType;
                if (index !== vm.applications.length - 1) {
                    loadImage(index + 1);
                }
            })
        }


    }
})();
