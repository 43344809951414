(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('DegreeDialogController', DegreeDialogController);

    DegreeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Degree'];

    function DegreeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Degree) {
        var vm = this;

        vm.degree = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.degree.id !== null) {
                Degree.update(vm.degree, onSaveSuccess, onSaveError);
            } else {
                Degree.save(vm.degree, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('hrmApp:degreeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
