(function () {
    'use strict';

    angular
        .module('hrmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('vacancy', {
                parent: 'entity',
                url: '/vacancy',
                data: {
                    authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN'],
                    pageTitle: 'hrmApp.vacancy.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/vacancy/vacancies.html',
                        controller: 'VacancyController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('vacancy');
                        $translatePartialLoader.addPart('hierarchicalLevel');
                        $translatePartialLoader.addPart('vacancyStatus');
                        $translatePartialLoader.addPart('jobType');
                        $translatePartialLoader.addPart('schedule');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('applicationStages');
                        $translatePartialLoader.addPart('swalMessages');
                        $translatePartialLoader.addPart('academicLevel');
                        return $translate.refresh();
                    }]
                }
            })
            .state('vacancy-detail', {
                parent: 'vacancy',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN'],
                    pageTitle: 'hrmApp.vacancy.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/vacancy/vacancy-detail.html',
                        controller: 'VacancyDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('vacancy');
                        $translatePartialLoader.addPart('hierarchicalLevel');
                        $translatePartialLoader.addPart('vacancyStatus');
                        $translatePartialLoader.addPart('jobType');
                        $translatePartialLoader.addPart('schedule');
                        $translatePartialLoader.addPart('applicant');
                        $translatePartialLoader.addPart('applicationStages');
                        $translatePartialLoader.addPart('swalMessages');
                        $translatePartialLoader.addPart('requirementFilter');
                        $translatePartialLoader.addPart('maritalStatus');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('academicDegree');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('languageLevel');
                        $translatePartialLoader.addPart('degree');
                        $translatePartialLoader.addPart('previousJob');
                        $translatePartialLoader.addPart('academicLevel');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('skill');
                        $translatePartialLoader.addPart('personDocument');
                        $translatePartialLoader.addPart('personDocumentType');
                        $translatePartialLoader.addPart('certification');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Vacancy', function ($stateParams, Vacancy) {
                        return Vacancy.getNoFile({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'vacancy',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            }).state('applicant-vacancy-detail', {
            parent: 'entity',
            url: '/vacancy-details/{id}',
            data: {
                authorities: [],
                pageTitle: 'hrmApp.vacancy.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vacancy/applicant-vacancy-detail.html',
                    controller: 'ApplicantVacancyDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vacancy');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('vacancyStatus');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('jobType');
                    $translatePartialLoader.addPart('schedule');
                    $translatePartialLoader.addPart('requirementFilter');
                    $translatePartialLoader.addPart('applicationStages');
                    $translatePartialLoader.addPart('swalMessages');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Vacancy', function ($stateParams, Vacancy) {
                    return Vacancy.get({id: $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'vacancy',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('vacancy-info', {
            parent: 'entity',
            url: '/vacancy-info/{id}',
            data: {
                authorities: [],
                pageTitle: 'hrmApp.vacancy.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vacancy/vacancy-info.html',
                    controller: 'VacancyInfoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vacancy');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('vacancyStatus');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('jobType');
                    $translatePartialLoader.addPart('schedule');
                    $translatePartialLoader.addPart('requirementFilter');
                    $translatePartialLoader.addPart('applicationStages');
                    $translatePartialLoader.addPart('swalMessages');
                    $translatePartialLoader.addPart('academicLevel');
                    $translatePartialLoader.addPart('languageLevel');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Vacancy', function ($stateParams, Vacancy) {
                    return Vacancy.get({id: $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'vacancy',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
            .state('vacancy-detail.edit', {
                parent: 'vacancy-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/vacancy/vacancy-dialog.html',
                        controller: 'VacancyDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Vacancy', function (Vacancy) {
                                return Vacancy.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('vacancy.new', {
                parent: 'vacancy',
                url: '/new',
                data: {
                    authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/vacancy/vacancy-dialog.html',
                        controller: 'VacancyDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    description: null,
                                    activity: null,
                                    created: null,
                                    finalDate: null,
                                    position: null,
                                    hierarchicalLevel: null,
                                    status: null,
                                    jobType: null,
                                    jobSchedule: null,
                                    vacancyRole: null,
                                    requirements: null,
                                    notes: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('vacancy', null, {reload: 'vacancy'});
                    }, function () {
                        $state.go('vacancy');
                    });
                }]
            })
            .state('vacancy.edit', {
                parent: 'vacancy',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/vacancy/vacancy-dialog.html',
                        controller: 'VacancyDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Vacancy', function (Vacancy) {
                                return Vacancy.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('vacancy', null, {reload: 'vacancy'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('vacancy-detail.cv', {
                parent: 'vacancy-detail',
                data: {
                    authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN']
                },
                params: {
                    applicantId: null
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/vacancy/cv-popup.html',
                        controller: 'CvDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg'
                    }).result.then(function () {
                        //$state.go('^', {}, {reload: false});
                    }, function () {
                        //$state.go('^');
                    });
                }]
            })
            .state('vacancy.delete', {
                parent: 'vacancy',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/vacancy/vacancy-delete-dialog.html',
                        controller: 'VacancyDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Vacancy', function (Vacancy) {
                                return Vacancy.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('vacancy', null, {reload: 'vacancy'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('vacancy-create', {
                parent: 'vacancy',
                url: '/create-vacancy',
                data: {
                    authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN'],
                    pageTitle: 'hrmApp.vacancy.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/vacancy/create-vacancy.html',
                        controller: 'NewVacancyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('vacancy');
                        $translatePartialLoader.addPart('hierarchicalLevel');
                        $translatePartialLoader.addPart('vacancyStatus');
                        $translatePartialLoader.addPart('jobType');
                        $translatePartialLoader.addPart('schedule');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('maritalStatus');
                        $translatePartialLoader.addPart('academicDegree');
                        $translatePartialLoader.addPart('academicLevel');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('requirementFilter');
                        $translatePartialLoader.addPart('category');
                        $translatePartialLoader.addPart('languageLevel');
                        $translatePartialLoader.addPart('swalMessages');
                        $translatePartialLoader.addPart('degree');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'vacancy',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            }).state('vacancy-edit', {
            parent: 'vacancy',
            url: '/{id}/edit-vacancy',
            data: {
                authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN'],
                pageTitle: 'hrmApp.vacancy.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vacancy/create-vacancy.html',
                    controller: 'NewVacancyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vacancy');
                    $translatePartialLoader.addPart('hierarchicalLevel');
                    $translatePartialLoader.addPart('vacancyStatus');
                    $translatePartialLoader.addPart('jobType');
                    $translatePartialLoader.addPart('schedule');
                    $translatePartialLoader.addPart('language');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('academicDegree');
                    $translatePartialLoader.addPart('academicLevel');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('requirementFilter');
                    $translatePartialLoader.addPart('category');
                    $translatePartialLoader.addPart('languageLevel');
                    $translatePartialLoader.addPart('degree');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'vacancy',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('vacancy-replicate', {
            parent: 'vacancy',
            url: '/replicate-vacancy',
            data: {
                authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN'],
                pageTitle: 'hrmApp.vacancy.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vacancy/create-vacancy.html',
                    controller: 'NewVacancyController',
                    controllerAs: 'vm'
                }
            },
            params: {
                isReplica: true,
                id: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vacancy');
                    $translatePartialLoader.addPart('hierarchicalLevel');
                    $translatePartialLoader.addPart('vacancyStatus');
                    $translatePartialLoader.addPart('jobType');
                    $translatePartialLoader.addPart('schedule');
                    $translatePartialLoader.addPart('language');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('academicDegree');
                    $translatePartialLoader.addPart('academicLevel');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('requirementFilter');
                    $translatePartialLoader.addPart('category');
                    $translatePartialLoader.addPart('languageLevel');
                    $translatePartialLoader.addPart('degree');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'vacancy',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('vacancy.send-email', {
            parent: 'vacancy-detail',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                applicants: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/applicant/send-applicant-email-dialog.html',
                    controller: 'SendMultipleApplicantEmailController',
                    controllerAs: 'vm',
                    size: 'lg'
                }).result.then(function () {
                    //$state.go('applicant', null, { reload: 'applicant' });
                }, function () {
                    //$state.go('^');
                });
            }]
        }).state('vacancy-detail.observation-modal', {
            parent: 'vacancy-detail',
            url: '/observation',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                action: null,
                ids: null,
                stage: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/vacancy/observation-dialog-modal.html',
                    controller: 'ObservationDialogModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function () {
                    $state.go('vacancy-detail', null, {reload: 'vacancy-detail'});
                }, function () {
                    $state.go('vacancy-detail');
                });
            }]
        });
    }

})();
