(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ReportsController', ReportsController);

    ReportsController.$inject = ['Vacancy', '$http', '$sce'];

    function ReportsController(Vacancy, $http, $sce) {

        var vm = this;

        $http({
            method: 'GET',
            url: '/api/vacancies-report/'
        }).then(function (response) {
            vm.report = response.data;
        }, function (response) {

        });


        vm.downloadReports = function (type) {
            vm.disableReport = true;
            var url, reportName;

            if (type === 'general') {
                url = 'api/general-report';
                reportName = 'Relatório Geral.xls';
            }

            else if (type === 'applicant') {
                url = '/api/general-applicant-report';
                reportName = 'Relatório Todos Candidatos.xls';
            }
            else if (type === 'application') {
                url = '/api/general-application-report';
                reportName = 'Relatório Todas Candidaturas.xls';
            }
            $http({
                method: 'GET',
                url: url,
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disableReport = false;
                var file = new Blob([response.data], {type: 'application/excel'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', reportName);
                downloadLink[0].click();
            }, function (response) {
                vm.disableReport = false;
            });
        };

        vm.downloadGeneralApplicationReport = function () {
            vm.disableReport = true;
            $http({
                method: 'GET',
                url: '/api/general-application-report',
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disableReport = false;
                var file = new Blob([response.data], {type: 'application/excel'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'Relatório Todas Candidaturas.xls');
                downloadLink[0].click();
            }, function (response) {
                vm.disableReport = false;
            });
        };

    }
})();
