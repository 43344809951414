(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantProfileController', ApplicantProfileController);

    ApplicantProfileController.$inject = ['$state', '$scope', 'Principal', 'Applicant', 'DataUtils', 'Language', '$http', 'AlertService', 'City', '$translate', 'Degree', 'Country', '$anchorScroll', 'Certification', 'PersonDocument','$uibModal'];

    function ApplicantProfileController($state, $scope, Principal, Applicant, DataUtils, Language, $http, AlertService, City, $translate, Degree, Country, $anchorScroll, Certification, PersonDocument,$uibModal) {

        var vm = this;
        vm.cities = City.query({size:5000});
        vm.spinner=true;
        getDate();
        $anchorScroll();
        vm.pullUpPage = function() {
            $anchorScroll();
        };
        var validImgFormats = ['jpg', 'png', 'jpeg'];
        //TODO fazer unico pedido GET
        Principal.identity().then(function (account) {
            vm.account = account;
            Applicant.getApplicantFromUser({userId:account.id}, function (result) {
                vm.applicant = result;
                vm.address = vm.applicant.address;
                vm.auxCountry=null;
                vm.countries = Country.query({size:5000}, function (result) {
                    for(var i=0; i<vm.countries.length; i++){
                        if(vm.countries[i].name===vm.address.description){
                            vm.auxCountry=vm.countries[i];
                            vm.spinner=false;
                            break
                        }
                    }
                    vm.address.description=vm.auxCountry;
                });

                vm.applicant.dateOfBirth=new Date(vm.applicant.dateOfBirth);
                $http({
                    method: 'GET',
                    url: '/api/academic-degrees-applicant/'+vm.applicant.id
                }).then(function (response) {
                    vm.academicDegrees=response.data;
                    vm.spinner=false;
                    $http({
                        method: 'GET',
                        url: '/api/person-documents-applicant/'+vm.applicant.id
                    }).then(function (response) {
                        vm.personDocuments=response.data;

                        $http({
                            method: 'GET',
                            url: '/api/previous-jobs-applicant/'+vm.applicant.id
                        }).then(function (response) {
                            vm.previousJobs=response.data;
                            certifications();
                            $http({
                                method: 'GET',
                                url: '/api/skills-applicant/'+vm.applicant.id
                            }).then(function (response) {
                                vm.skill=response.data[0];
                            }, function errorCallback(response) {
                                AlertService.error("Erro!");
                            });


                        }, function errorCallback(response) {
                            AlertService.error("Erro!");
                        });


                    }, function errorCallback(response) {
                        AlertService.error("Erro!");
                    });

                }, function errorCallback(response) {
                    AlertService.error("Erro!");
                });


                translations()
            });
        });

        function certifications(){
            $http({
                method: 'GET',
                url: '/api/certifications-applicant/'+vm.applicant.id
            }).then(function (response) {
                vm.certifications=response.data;



            }, function errorCallback(response) {
                AlertService.error("Erro!");
            });
        }

        function personDocuments() {
            $http({
                method: 'GET',
                url: '/api/person-documents-applicant/'+vm.applicant.id
            }).then(function (response) {
                vm.personDocuments=response.data;

            }, function errorCallback(response) {
                AlertService.error("Erro!");
            });
        }

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;

        vm.languages = Language.query({size:5000});
        vm.academicDegrees =[];
        vm.addAD = function () {
            var found = false;
            var exist = false;
            var indexDegree = 0;

            angular.forEach(vm.dbDegrees, function (value, key) {
                if(value.name === vm.academicDegree.degree){
                    //console.debug(value);
                    exist = true;
                    indexDegree = key;
                }
            });

            if(!exist){
                vm.newDegree={};
                vm.newDegree.name=vm.academicDegree.degree;
                vm.newDegree.active=true;
                Degree.save(vm.newDegree, function (res) {
                    //
                    //console.debug("Saved")
                    vm.academicDegree.degree = res;
                    for(var i=0; i<vm.academicDegrees.length; i++){
                        if(vm.academicDegrees[i].institution == vm.academicDegree.institution
                            && vm.academicDegrees[i].level == vm.academicDegree.level){
                            found = true;
                        }
                    }
                    if(!found){
                        vm.academicDegrees.push(vm.academicDegree);
                        vm.academicDegree=null;
                    }
                    vm.charCount();
                });
            }else{
                vm.academicDegree.degree = vm.dbDegrees[indexDegree];
                for(var i=0; i<vm.academicDegrees.length; i++){
                    if(vm.academicDegrees[i].institution == vm.academicDegree.institution
                        && vm.academicDegrees[i].level == vm.academicDegree.level){
                        found = true;
                    }
                }
                if(!found){
                    vm.academicDegrees.push(vm.academicDegree);
                    vm.academicDegree=null;
                }
                vm.charCount();
            }


        };
        Degree.query({size:5000}, function (result) {
            vm.dbDegrees = result;
            vm.degrees = [];
            angular.forEach(result, function (value, key) {
                vm.degrees.push(value.name)
            });
            vm.getDegrees = function(search) {
                var newSupes = vm.degrees.slice();
                if (search && newSupes.indexOf(search) === -1) {
                    newSupes.unshift(search);
                }
                return newSupes;
            };
        });




        vm.previousJobs =[];
        vm.addPJ = function () {

            var found = false;
            for(var i=0; i<vm.previousJobs.length; i++){
                if(vm.previousJobs[i].institution == vm.previousJob.institution
                    && vm.previousJobs[i].fromDate == vm.previousJob.fromDate
                    && vm.previousJobs[i].toDate == vm.previousJob.toDate){
                    found = true;
                }
            }
            if(!found){
                if(vm.previousJob.currentJob){
                    vm.previousJob.toDate=null;
                }
                vm.previousJobs.push(vm.previousJob);
                vm.previousJob=null;
            }
            vm.jobCharCount();
        };

        vm.personDocuments =[];
        vm.certifications =[];
        vm.addPD = function () {
            //vm.personDocuments.push(vm.personDocument);
            //vm.personDocument=null;
            vm.isSaving=true;
            $http({
                method: 'POST',
                url: '/api/person-document-appl/'+vm.applicant.id,
                data: vm.personDocument
            }).then(function () {
                vm.isSaving=false;
                vm.personDocument=null;
                personDocuments();
            }, onSaveError);

        };
        vm.addCERT = function () {
            $uibModal.open({
                templateUrl: 'app/entities/certification/certification-dialog.html',
                controller: 'CertificationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            title: null,
                            date: null,
                            file: null,
                            fileContentType: null,
                            description: null,
                            id: null,
                            applicant_id: vm.applicant.id
                        };
                    }
                }
            }).result.then(function() {
                certifications()
            }, function() {

            });

        };

        function removeItemDialog(variable, index){
            $translate('hrmApp.swalMessages.removeItem').then(function (translation) {
                swal({
                    title: '',
                    text: translation,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.yes,
                    cancelButtonText: vm.no,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        variable.splice(index, 1);
                    }
                })
            });
        }

        vm.removeAD = function (index) {
            removeItemDialog(vm.academicDegrees, index)

        };
        vm.editAD = function (index) {
            vm.academicDegree = vm.academicDegrees[index];
            vm.academicDegree.degree=vm.academicDegrees[index].degree.name;
            vm.academicDegrees.splice(index, 1);
            $anchorScroll();
        };
        vm.removePJ = function (index) {
            removeItemDialog(vm.previousJobs, index)
        };
        vm.editPJ = function (index) {
            vm.previousJob=vm.previousJobs[index];
            vm.previousJobs.splice(index, 1);
            $anchorScroll();
        };
        vm.removePD = function (document) {
            $translate('hrmApp.swalMessages.removeItem').then(function (translation) {
                swal({
                    title: '',
                    text: translation,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.yes,
                    cancelButtonText: vm.no,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        PersonDocument.delete({id:document.id}, function () {
                            personDocuments();
                        })
                    }
                })
            });
        };
        vm.removeCERT = function (index) {

            //removeItemDialog(vm.certifications, index)
            $translate('hrmApp.swalMessages.removeItem').then(function (translation) {
                swal({
                    title: '',
                    text: translation,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.yes,
                    cancelButtonText: vm.no,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        Certification.delete({id:vm.certifications[index].id}, function () {
                            certifications();
                        })
                    }
                })
            });
        };
        vm.editCERT = function (certification) {
            /*
            vm.certification= vm.certifications[index];
            vm.certifications.splice(index, 1);*/
            vm.isLoadingFile = true;
            Certification.get({id:certification.id}, function (res) {
                $uibModal.open({
                    templateUrl: 'app/entities/certification/certification-dialog.html',
                    controller: 'CertificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: res
                    }
                }).result.then(function() {
                    certifications()
                    vm.isLoadingFile = false;
                }, function() {
                    vm.isLoadingFile = false;
                });
            });

        };

        vm.setFile = function ($file, personDocument) {
            var value=$file.name;
            var ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();
            if(ext=='pdf'){
                if($file.size>1048576){
                    $translate('hrmApp.swalMessages.fileSizeError').then(function (translation) {
                        swal("", translation, "error");
                    });
                }else{
                    if ($file) {
                        DataUtils.toBase64($file, function(base64Data) {
                            $scope.$apply(function() {
                                personDocument.file = base64Data;
                                personDocument.fileContentType = $file.type;
                            });
                        });
                    }
                }
            } else{
                $translate('hrmApp.swalMessages.fileFormatErrorDoc').then(function (translation) {
                    swal("", translation, "error");
                });
            }
        };
        vm.savePreliminar = function () {
            vm.isSaving = true;
            vm.address.description=vm.address.description.name;
            vm.data ={};
            vm.data.applicant = vm.applicant;
            vm.data.address = vm.address;
            vm.data.academicDegrees = vm.academicDegrees;
            vm.data.previousJobs = vm.previousJobs;
            //vm.data.personDocuments = vm.personDocuments;
            vm.data.certifications = vm.certifications;
            //vm.data.skill = vm.skill;
            if(angular.isDefined(vm.skill)){
                vm.data.skills = vm.skill.skills;
                vm.data.profile = vm.skill.profile;
                vm.data.hobbies = vm.skill.hobbies;
                vm.data.objectives = vm.skill.objectives;
            }

            $http({
                method: 'PUT',
                url: '/api/applicants-form-update',
                data: vm.data
            }).then(function (response) {
                vm.isSaving = false;
            }, function errorCallback(response) {
                AlertService.error("Erro ao salvar!");
            });
        };

        function save () {
            vm.isSaving = true;
            vm.address.description=vm.address.description.name;
            vm.data ={};
            vm.data.applicant = vm.applicant;
            vm.data.address = vm.address;
            vm.data.academicDegrees = vm.academicDegrees;
            vm.data.previousJobs = vm.previousJobs;
            vm.data.personDocuments = vm.personDocuments;
            //vm.data.certifications = vm.certifications;
            //vm.data.skill = vm.skill;
            if(angular.isDefined(vm.skill)){
                vm.data.skills = vm.skill.skills;
                vm.data.profile = vm.skill.profile;
                vm.data.hobbies = vm.skill.hobbies;
                vm.data.objectives = vm.skill.objectives;
            }
            //console.log(vm.data.skill)
            //vm.data.languages = vm.personDocuments;
            $http({
                method: 'PUT',
                url: '/api/applicants-form-update',
                data: vm.data
            }).then(onSaveSuccess, function errorCallback(response) {
                AlertService.error("Erro ao salvar!");
            });

        }

        function onSaveSuccess (result) {

            $http({
                method: 'GET',
                url: '/api/applicants/data/'+vm.account.id
            }).then(function (result) {
                vm.percentage = result.data;
                showMessages(vm.percentage)
            }, function errorCallback(response) {
                AlertService.error("Erro ao salvar!");
            });

            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        function translations(){
            $translate('hrmApp.swalMessages.vacancySavedSuccess').then(function (translation) {
                vm.savedSuccess = translation
            });
            $translate('hrmApp.swalMessages.incompleteData2').then(function (translation) {
                vm.incomplete = translation
            });
            $translate('hrmApp.swalMessages.applyAsk').then(function (translation) {
                vm.applyAsk = translation
            });
            $translate('hrmApp.swalMessages.yes').then(function (translation) {
                vm.yes = translation
            });
            $translate('hrmApp.swalMessages.no').then(function (translation) {
                vm.no = translation
            });
            $translate('hrmApp.swalMessages.fileNotFound').then(function (translation) {
                vm.fileNotFound = translation
            });
        }


        function showMessages(percent){
            if(percent<60){

                swal({
                    title: vm.savedSuccess,
                    text: vm.incomplete,
                    type: "info"
                }, function () {
                    $state.go('applicant-profile-home');
                })
            }else{

                swal({
                    title: vm.savedSuccess,
                    text: vm.applyAsk,
                    type: "success",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.yes,
                    cancelButtonText: vm.no,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        $state.go('home');
                    } else {
                        $state.go('applicant-profile-home');
                    }
                })
            }
        }

        vm.datePickerOpenStatus.dateOfBirth = false;
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        vm.setImage = function ($file, applicant) {

            var value=$file.name;
            var ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();
            if(validImgFormats.indexOf(ext) !== -1){
                if($file.size>1048576){
                    $translate('hrmApp.swalMessages.fileSizeError').then(function (translation) {
                        swal("", translation, "error");
                    });
                }else{
                    if ($file) {
                        DataUtils.toBase64($file, function(base64Data) {
                            $scope.$apply(function() {
                                applicant.image = base64Data;
                                applicant.imageContentType = $file.type;
                            });
                        });
                    }
                }
            } else{
                $translate('hrmApp.swalMessages.fileFormatErrorImg').then(function (translation) {
                    swal("", translation, "error");
                });
            }
        };

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.tabNames =
        {'tab1':0, 'tab2':1, 'tab3':2, 'tab4':3, 'tab5':4, 'tab6':5 };
        vm.activeTab = vm.tabNames.tab1;
        vm.clear = function () {

            $translate('hrmApp.swalMessages.areYouSure').then(function (translation) {
                swal({
                    title: '',
                    text: translation,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: vm.yes,
                    cancelButtonText: vm.no,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        $state.go('applicant-profile-home');
                    }
                })
            });


        };

        //TODO passar os charCount para unica funcao
        vm.charCount = function () {
            if(angular.isDefined(vm.academicDegree)  && vm.academicDegree!==null){
                if(angular.isDefined(vm.academicDegree.notes) && vm.academicDegree.notes!==null){
                    return 500 - vm.academicDegree.notes.length
                }else{
                    return 2000
                }
            }else{
                return 500
            }
        };

        vm.jobCharCount = function () {
            if(angular.isDefined(vm.previousJob) && vm.previousJob!==null){
                if(angular.isDefined(vm.previousJob.description)  && vm.previousJob.description!==null){
                    return 2000 - vm.previousJob.description.length
                }else{
                    return 2000
                }

            }else{
                return 2000
            }
        };

        vm.profileCharCount = function () {
            if(angular.isDefined(vm.skill) && vm.skill!==null){
                if(angular.isDefined(vm.skill.profile)  && vm.skill.profile!==null){
                    return 2000 - vm.skill.profile.length
                }else{
                    return 2000
                }

            }else{
                return 2000
            }
        };

        vm.objectivesCharCount = function () {
            if(angular.isDefined(vm.skill)  && vm.skill!==null ){
                if(angular.isDefined(vm.skill.objectives)   && vm.skill.objectives!==null){
                    return 2000 - vm.skill.objectives.length
                }else{
                    return 2000
                }
            }else{
                return 2000
            }
        };
        vm.hobbiesCharCount = function () {
            if(angular.isDefined(vm.skill)  && vm.skill!==null) {
                if(angular.isDefined(vm.skill.hobbies)  && vm.skill.hobbies!==null){
                    return 2000 - vm.skill.hobbies.length
                }else{
                    return 2000
                }

            }else{
                return 2000
            }
        };

        vm.calculateAge = function calculateAge(start, end) { // birthday is a date
            var brt = new Date(start);
            var brtEnd = null;
            if(end=='currentJob'){
                brtEnd = vm.currentDate;
            }else{
                brtEnd = new Date(end);
            }

            var ageDifMs = brtEnd - brt.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            var res = Math.abs(ageDate.getUTCFullYear() - 1970);
            if(res==0){
                return brtEnd.getMonth() - brt.getMonth()
                    + (12 * (brtEnd.getFullYear() - brt.getFullYear())) + ' Meses';
            }else{
                return res;
            }
        };


        function getDate() {
            $http({
                method: 'GET',
                url: '/api/current-date'
            }).then(function successCallback(response) {
                vm.currentDate = new Date(response.data);
            }, function () {

            });
        }

        vm.openEntityFile = function (entity, type) {
            vm.isLoadingFile = true;
            if(type==='CERT'){
                Certification.get({id:entity.id}, function (result) {
                    if(result.file){
                        vm.openFile(result.fileContentType, result.file);
                        vm.isLoadingFile = false;
                    } else {
                        fileNotFound();
                    }
                })
            }
            if(type==='PD'){
                PersonDocument.get({id:entity.id}, function (result) {
                    if(result.file){
                        vm.openFile(result.fileContentType, result.file);
                        vm.isLoadingFile = false;
                    } else {
                        fileNotFound();
                    }
                })
            }
        };


        function fileNotFound() {
            swal({
                title: '',
                text: vm.fileNotFound,
                type: "warning"
            });
            vm.isLoadingFile = false;
        }

    }
})();
