(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('HowToApplyController', HowToApplyController);

    HowToApplyController.$inject = ['Company', '$state', '$translate'];

    function HowToApplyController(Company, $state, $translate) {

        var vm = this;
        vm.isSaving = false;
        Company.getFirst(function (result) {
            vm.company=result[0];
        });

        vm.save = function () {
            vm.isSaving = true;
            Company.update(vm.company, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess (result) {

            $translate('hrmApp.swalMessages.vacancySavedSuccess').then(function (translation) {
                swal(translation, " ", "success");
            });
            $state.go('home');
            vm.isSaving = false;
        }

        function onSaveError () {
            $translate('hrmApp.swalMessages.error').then(function (translation) {
                swal(translation, " ", "success");
            });
            vm.isSaving = false;
        }

    }
})();
