(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicationDeleteController',ApplicationDeleteController);

    ApplicationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Application','Principal'];

    function ApplicationDeleteController($uibModalInstance, entity, Application, Principal) {
        var vm = this;

        vm.application = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.isDeleting=false;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            vm.isDeleting=true;
            Principal.identity().then(function (account) {

                Application.deleteByUser({id: id, user:account.id},
                    function () {
                        $uibModalInstance.close(true);
                    });
            });

        }
    }
})();
