(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('VacancyDialogController', VacancyDialogController);

    VacancyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Vacancy', 'Category', 'City', 'Application'];

    function VacancyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Vacancy, Category, City, Application) {
        var vm = this;

        vm.vacancy = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.categories = Category.query({size:5000});
        vm.cities = City.query({size:5000});
        vm.applications = Application.query({size:5000});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.vacancy.id !== null) {
                Vacancy.update(vm.vacancy, onSaveSuccess, onSaveError);
            } else {
                Vacancy.save(vm.vacancy, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('hrmApp:vacancyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.created = false;
        vm.datePickerOpenStatus.finalDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
