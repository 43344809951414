(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('RemoveApplicationDialogController',RemoveApplicationDialogController);

    RemoveApplicationDialogController.$inject = ['$uibModalInstance', 'entity', 'Application', 'AlertService', '$state', '$stateParams', '$rootScope'];

    function RemoveApplicationDialogController($uibModalInstance, entity, Application, AlertService, $state, $stateParams, $rootScope) {
        var vm = this;

        vm.application = entity;
        vm.clear = clear;
        vm.confirmRemoval = confirmRemoval;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmRemoval (id) {

            vm.stagesModel = {};
            vm.stagesModel.observation = vm.observations;
            vm.stagesModel.applicationID = id;
            vm.stagesModel.remove = true;

            Application.observationAndAction(vm.stagesModel
                , function (result) {
                    $uibModalInstance.dismiss();
                    $rootScope.$broadcast('application-set');
                    $state.reload('vacancy-detail', {id: $stateParams.id});
                },
                function (error) {
                    AlertService.warning('Não foi possível remover a candidatura do candidato' + vm.application.applicant.name + ' tente novamente');
                });
        }
    }
})();
