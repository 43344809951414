(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantVacancyDetailController', ApplicantVacancyDetailController);

    ApplicantVacancyDetailController.$inject = ['$anchorScroll', 'Application', 'Principal', 'previousState', 'entity', 'Applicant', 'AlertService', 'LoginService', 'DataUtils', '$translate', '$http', '$state'];

    function ApplicantVacancyDetailController($anchorScroll, Application, Principal, previousState, entity, Applicant, AlertService, LoginService, DataUtils, $translate, $http, $state) {
        var vm = this;
        vm.isAuthenticated = false;
        vm.vacancy = entity;
        vm.previousState = previousState.name;
        vm.tabNames =
        {'tab1':0, 'tab2':1, 'tab3':2, 'tab4':3, 'tab5':4, 'tab6':5 };
        vm.activeTab = vm.tabNames.tab1;
        vm.openFile = DataUtils.openFile;
        $anchorScroll();

        vm.applyToVacancy = function () {

            Principal.identity().then(function (account) {
                vm.account = account;


                vm.isAuthenticated = Principal.isAuthenticated;
                if(vm.account!=null){
                    var userId = vm.account.id;

                    $http({
                        method: 'GET',
                        url: '/api/applicants/data/'+vm.account.id
                    }).then(function (response) {
                        vm.percentage = response.data;


                            if(vm.account.authorities.indexOf('ROLE_RH_MANAGER') == -1 && vm.account.authorities.indexOf('ROLE_ADMIN') == -1 && vm.account.authorities.indexOf('ROLE_RH_TECHNICIAN') == -1){
                                if(vm.percentage>=60){
                                    Applicant.getApplicantFromUser({userId: userId},
                                        function (applicantResult) {
                                            vm.application = {};
                                            vm.application.applicant = applicantResult;
                                            vm.application.vacancy = vm.vacancy;

                                            Application.save(vm.application,
                                                function(result){
                                                    //AlertService.success(vm.applySuccess);
                                                    $translate('hrmApp.swalMessages.applySuccess').then(function (translation) {
                                                        swal({
                                                            title: "Sucesso!",
                                                            text: translation,
                                                            type: "success",
                                                            confirmButtonText: "OK"
                                                        });
                                                    });

                                                },
                                                function(error){
                                                    if(error.status == 304){
                                                        //AlertService.warning(vm.appliedAlready);
                                                        $translate('hrmApp.swalMessages.appliedAlready').then(function (translation) {
                                                            swal({
                                                                title: "Atenção!",
                                                                text:  translation,
                                                                type: "warning",
                                                                confirmButtonText: "OK"
                                                            });
                                                        });

                                                    }
                                                    else{
                                                        //AlertService.error('Erro ao concorrer, tente novamente ...');
                                                        $translate('hrmApp.swalMessages.applyError').then(function (translation) {
                                                            swal({
                                                                title: "Erro!",
                                                                text: translation,
                                                                type: "error",
                                                                confirmButtonText: "OK"
                                                            });
                                                        });

                                                    }
                                                })
                                        },
                                        function (error) {
                                            //AlertService.error('Erro, tente novamente ...');
                                            $translate('hrmApp.swalMessages.error').then(function (translation) {
                                                swal({
                                                    title: "Erro!",
                                                    text: translation,
                                                    type: "error",
                                                    confirmButtonText: "OK"
                                                });
                                            });
                                        })
                            } else{
                                $translate('hrmApp.swalMessages.incompleteData').then(function (translation) {
                                    swal({
                                        title: "Erro!",
                                        text: translation,
                                        type: "error",
                                        confirmButtonText: "OK"
                                    }, function () {
                                        $state.go('applicant-profile');
                                    })
                                });
                                }
                        }else{
                            $translate('hrmApp.swalMessages.invalidRole').then(function (translation) {
                                swal({
                                    title: "Erro!",
                                    text: translation,
                                    type: "error",
                                    confirmButtonText: "OK"
                                });
                            });
                        }

                    }, function errorCallback(response) {
                        AlertService.error("Erro!");
                    });



                }else{


                    $translate('hrmApp.swalMessages.registerFirst').then(function (translation) {
                        vm.rg1=translation;
                        $translate('hrmApp.swalMessages.login').then(function (translation) {
                            vm.loginTxt=translation;
                            $translate('hrmApp.swalMessages.help').then(function (translation) {
                                vm.helpTxt=translation;
                                $translate('hrmApp.swalMessages.registerFirst2').then(function (translation) {
                                    swal({
                                        title: vm.rg1,
                                        text: translation,
                                        type: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#1c84c6",
                                        cancelButtonColor: "#f8ac59",
                                        cancelButtonText: vm.loginTxt,
                                        confirmButtonText: vm.helpTxt
                                    }, function (isConfirm) {
                                        if(isConfirm){
                                            /*
                                            $http({
                                                method: 'GET',
                                                url: '/api/faq-file'
                                            }).then(function (res) {
                                                vm.company = res.data;
                                                if(vm.company.file!=null){
                                                    vm.openFile = DataUtils.openFile;
                                                    vm.openFile(vm.company.fileContentType, vm.company.file)
                                                }
                                            });*/
                                            $state.go('help');
                                        }else{
                                            LoginService.open();
                                        }
                                        //$state.go('applicant-profile');
                                    });
                                });
                            });
                        });
                    });






                    //LoginService.open();
                }

            });

        }
    }
})();
