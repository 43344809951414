(function () {
    'use strict';
    angular
        .module('hrmApp')
        .factory('Vacancy', Vacancy);

    Vacancy.$inject = ['$resource', 'DateUtils'];

    function Vacancy($resource, DateUtils) {
        var resourceUrl = 'api/vacancies/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'queryHome': {method: 'GET', url: 'api/vacancies-home/', isArray: true},
            'queryHomeUserRelated': {method: 'GET', url: 'api/vacancies-home-user-related/:userId', isArray: true},
            'queryUserRelated': {method: 'GET', url: 'api/vacancies-user-related/:userId', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.finalDate = DateUtils.convertDateTimeFromServer(data.finalDate);
                    }
                    return data;
                }
            },
            'getNoFile': {
                method: 'GET',
                url: 'api/vacancies/no-file/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.finalDate = DateUtils.convertDateTimeFromServer(data.finalDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'vacancySearchFilter': {
                url: 'api/vacancies/search-filter/:category/:city/:date',
                method: 'GET',
                isArray: true
            },
            'activate': {
                url: 'api/vacancies/activate/:id',
                'method': 'POST'
            },
            'deactivate': {
                'method': 'POST',
                url: 'api/vacancies/deactivate/:id'
            },
            'cancel': {
                'method': 'POST',
                url: 'api/vacancies/cancel/:id'
            },
            'getHomeVacancies': {
                'method': 'GET',
                url: 'api/home-vacancies',
                isArray: true
            },
            'getVacanciesForApplicant': {
                'method': 'GET',
                url: 'api/vacancies-for-applicant/:id',
                isArray: true
            },
            'getSearchVacancies': {
                'method': 'GET',
                url: 'api/home-vacancies/:searchParam',
                isArray: true
            },
            'adminSearchVacancy': {
                'method': 'GET',
                url: 'api/admin-vacancy-search/:searchParam/:status/:city/:fromDate/:toDate',
                isArray: true
            },
            'manualVacancyFilter': {
                method: 'PUT',
                url: 'api/manual-vacancy-filter'
            },
            'getInternship': {
                method: 'GET',
                url: 'api/home-internship',
                isArray: true
            }
        });
    }
})();
