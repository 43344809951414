(function () {
    'use strict';

    angular
        .module('hrmApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$scope', '$http', 'DataUtils', 'bowser', '$translate', 'ngNotify', '$window'];

    function NavbarController($state, Auth, Principal, ProfileService, LoginService, $scope, $http, DataUtils, bowser, $translate, ngNotify, $window) {
        var vm = this;

        browserSupport()
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.talentos = function(){
            $window.open("https://talentos.co.mz", "_blank");
        };

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.downloadInstructions = downloadInstructions;
        vm.$state = $state;
        checkAuthorities('refresh');
        $http({
            method: 'GET',
            url: '/api/internship-active'
        }).then(function (res) {
            vm.activeProgram = res.data;
        });


        $scope.$on('authenticationSuccess', function () {
            checkAuthorities();
        });

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
            vm.setNavActive('home');
            vm.isApplicant = true;
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function downloadInstructions() {
            //vm.company = Company.getFaq();
            $http({
                method: 'GET',
                url: '/api/faq-file'
            }).then(function (res) {
                vm.company = res.data;
                if (vm.company.file != null) {
                    vm.openFile = DataUtils.openFile;
                    vm.openFile(vm.company.fileContentType, vm.company.file)
                }
            });


        }

        /*
        $http({
            method: 'GET',
            url: '/api/faq-link'
        }).then(function (res) {
            vm.company = res.data;
        });*/

        vm.selectedStyle = {
            "color": "black"
        };

        function reloadStyle() {
            vm.styleVacancy = {};
            vm.styleVacancy2 = {};
            vm.styleApplicant = {};
            vm.styleReport = {};
            vm.styleAbout = {};
            vm.styleInternship = {};
        }

        vm.setNavActive = function (param) {
            reloadStyle();
            if (param == 'home') {
                vm.styleVacancy = vm.selectedStyle;
            }
            if (param == 'about') {
                vm.styleAbout = vm.selectedStyle;
            }
            if (param == 'report') {
                vm.styleReport = vm.selectedStyle;
            }
            if (param == 'applicant') {
                vm.styleApplicant = vm.selectedStyle;
            }
            if (param == 'vacancy') {
                vm.styleVacancy2 = vm.selectedStyle;
            }
            if (param == 'internship') {
                vm.styleInternship = vm.selectedStyle;
            }
        };


        function checkAuthorities(param) {
            if (vm.isAuthenticated()) {
                Principal.identity().then(function (account) {
                    vm.account = account;
                    if (vm.account.authorities.indexOf('ROLE_APPLICANT') !== -1) {
                        vm.setNavActive('home');
                        vm.isApplicant = true;
                    } else if (vm.account.authorities.indexOf('ROLE_ADMIN') !== -1) {
                        vm.isApplicant = false;
                        if (param !== 'refresh') {
                            $state.go('vacancy');
                        }

                    } else if (vm.account.authorities.indexOf('ROLE_HR_MANAGER') == -1 || vm.account.authorities.indexOf('ROLE_HR_TECHNICIAN') == -1) {
                        vm.isApplicant = false;
                        if (param !== 'refresh') {
                            $state.go('vacancy');
                            vm.setNavActive('vacancy');
                        }
                    }
                })
            } else {
                vm.isApplicant = true;
            }
        }

        function browserSupport() {
            if (!bowser.chrome) {
                //notify('Utilize o google chrome para melhor performance');
                $translate('browser').then(function (translation) {
                    ngNotify.set(translation, {
                        sticky: true,
                        html: true,
                        type: 'warn'
                    });
                });

            }
        }

        vm.compressImages = function () {
            vm.collapseNavbar();
            swal({
                title: 'Compress Images',
                text: 'This will run a background task to compress all applicant images (0.5 ratio) saving you approximately 50% space. Continue?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    $http({
                        method: 'GET',
                        url: '/api/compress-images'
                    }).then(function (res) {

                    });
                }
            })
        }
    }
})();
