(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantProfileHomeController', ApplicantProfileHomeController);

    ApplicantProfileHomeController.$inject = ['$state','$sce', 'Principal', 'Applicant', 'DataUtils', '$translate','$http','AlertService','$anchorScroll', 'PersonDocument','Certification'];

    function ApplicantProfileHomeController($state,$sce, Principal, Applicant, DataUtils,$translate,$http,AlertService,$anchorScroll,PersonDocument,Certification ) {

        var vm = this;
        $anchorScroll();

        getDate();
        vm.spinner=true;

        Principal.identity().then(function (account) {
            vm.account = account;
            Applicant.getApplicantFromUser({userId:account.id}, function (result) {
                vm.applicant = result;
                vm.address = vm.applicant.address;
                vm.spinner=false;
                $http({
                    method: 'GET',
                    url: '/api/academic-degrees-applicant/'+vm.applicant.id
                }).then(function (response) {
                    vm.academicDegrees=response.data;
                    $http({
                        method: 'GET',
                        url: '/api/person-documents-applicant/'+vm.applicant.id
                    }).then(function (response) {
                        vm.personDocuments=response.data;
                        $http({
                            method: 'GET',
                            url: '/api/previous-jobs-applicant/'+vm.applicant.id
                        }).then(function (response) {
                            vm.previousJobs=response.data;
                            $http({
                                method: 'GET',
                                url: '/api/applicant-applications2/' + vm.applicant.id
                            }).then(function successCallback(result) {

                                vm.applications = result.data;
                            }, function (err) {
                                console.debug(err);
                            })
                            $http({
                                method: 'GET',
                                url: '/api/applicants/data/'+vm.account.id
                            }).then(function (response) {
                                vm.percentage={width: response.data+'%'};
                                vm.percentValue = response.data;
                            });
                            $http({
                                method: 'GET',
                                url: '/api/skills-applicant/'+vm.applicant.id
                            }).then(function (response) {
                                $http({
                                    method: 'GET',
                                    url: '/api/certifications-applicant/'+vm.applicant.id
                                }).then(function (response) {
                                    vm.certifications=response.data;
                                }, function errorCallback(response) {
                                    AlertService.error("Erro!");
                                });

                                vm.skill=response.data[0];

                            }, function errorCallback(response) {
                                AlertService.error("Erro!");
                            });
                        }, function errorCallback(response) {
                            AlertService.error("Erro!");
                        });
                    }, function errorCallback(response) {
                        AlertService.error("Erro!");
                    });
                }, function errorCallback(response) {
                    AlertService.error("Erro!");
                });




            });

        });
        vm.openFile = DataUtils.openFile;
        ;

        vm.calculateAge = function calculateAge(start, end) { // birthday is a date
            var brt = new Date(start);
            var brtEnd = null;
            if(end=='currentJob'){
                brtEnd = vm.currentDate;
            }else{
                brtEnd = new Date(end);
            }

            var ageDifMs = brtEnd - brt.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            var res = Math.abs(ageDate.getUTCFullYear() - 1970);
            if(res==0){
                return brtEnd.getMonth() - brt.getMonth()
                    + (12 * (brtEnd.getFullYear() - brt.getFullYear())) + ' Meses';
            }else{
                return res;
            }
        };

        vm.calculateAge2 = function calculateAge(birthday) { // birthday is a date
            var brt = new Date(birthday);
            var ageDifMs = vm.currentDate - brt.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };

        function getDate() {
            $http({
                method: 'GET',
                url: '/api/current-date'
            }).then(function successCallback(response) {
                vm.currentDate = new Date(response.data);
            }, function () {

            });
        }

        vm.clear = function () {
            $state.go('home');
        };

        vm.openEntityFile = function (entity, type) {
            vm.isLoadingFile = true;
            $translate('hrmApp.swalMessages.fileNotFound').then(function (translation) {
                vm.fileNotFound = translation;
            });
            if(type==='CERT'){
                Certification.get({id:entity.id}, function (result) {
                    if(result.file){
                        vm.openFile(result.fileContentType, result.file);
                        vm.isLoadingFile = false;
                    } else {
                        fileNotFound();
                    }
                });
            }
            if(type==='PD'){
                PersonDocument.get({id:entity.id}, function (result) {
                    if(result.file){
                        vm.openFile(result.fileContentType, result.file);
                        vm.isLoadingFile = false;
                    } else {
                        fileNotFound();
                    }
                });
            }
        };


        function fileNotFound() {
            swal({
                title: '',
                text: vm.fileNotFound,
                type: "warning"
            });
            vm.isLoadingFile = false;
        }

        vm.downloadCV = function () {
            vm.disablePdf = true;
            $http({
                method: 'GET',
                url: '/api/applicant-pdf/'+vm.applicant.id,
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disablePdf = false;
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'Curriculum-Vitae.pdf');
                downloadLink[0].click();
            }, function (response) {
                vm.disablePdf = false;
            });
        };

    }
})();
