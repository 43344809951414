(function() {
    'use strict';

    angular
        .module('hrmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('applicant', {
            parent: 'entity',
            url: '/applicant',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'hrmApp.applicant.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/applicant/applicants.html',
                    controller: 'ApplicantController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicant');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('vacancy');
                    $translatePartialLoader.addPart('academicDegree');
                    $translatePartialLoader.addPart('academicLevel');
                    return $translate.refresh();
                }]
            }
        }).state('applicant-profile', {
            parent: 'applicant-profile-home',
            url: '/my-data',
            data: {
                authorities: ['ROLE_APPLICANT'],
                pageTitle: 'hrmApp.applicant.home.edit'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/applicant/applicant-profile.html',
                    controller: 'ApplicantProfileController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicant');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('academicDegree');
                    $translatePartialLoader.addPart('academicLevel');
                    $translatePartialLoader.addPart('previousJob');
                    $translatePartialLoader.addPart('personDocument');
                    $translatePartialLoader.addPart('personDocumentType');
                    $translatePartialLoader.addPart('language');
                    $translatePartialLoader.addPart('swalMessages');
                    $translatePartialLoader.addPart('degree');
                    $translatePartialLoader.addPart('skill');
                    $translatePartialLoader.addPart('certification');
                    return $translate.refresh();
                }]
            }
        }).state('applicant-profile-home', {
            parent: 'entity',
            url: '/profile',
            data: {
                authorities: ['ROLE_APPLICANT'],
                pageTitle: 'hrmApp.applicant.home.profile'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/applicant/applicant-profile-home.html',
                    controller: 'ApplicantProfileHomeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicant');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('academicDegree');
                    $translatePartialLoader.addPart('academicLevel');
                    $translatePartialLoader.addPart('previousJob');
                    $translatePartialLoader.addPart('personDocument');
                    $translatePartialLoader.addPart('personDocumentType');
                    $translatePartialLoader.addPart('languageLevel');
                    $translatePartialLoader.addPart('certification');
                    $translatePartialLoader.addPart('skill');
                    $translatePartialLoader.addPart('vacancy');
                    return $translate.refresh();
                }]
            }
        })
        .state('applicant-detail', {
            parent: 'applicant',
            url: '/applicant/{id}',
            data: {
                authorities: ['ROLE_RH_MANAGER', 'ROLE_RH_TECHNICIAN'],
                pageTitle: 'hrmApp.applicant.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/applicant/applicant-profile-home.html',
                    controller: 'ApplicantDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicant');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('languageLevel');
                    $translatePartialLoader.addPart('academicLevel');
                    $translatePartialLoader.addPart('previousJob');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('skill');
                    $translatePartialLoader.addPart('personDocument');
                    $translatePartialLoader.addPart('personDocumentType');
                    $translatePartialLoader.addPart('certification');
                    $translatePartialLoader.addPart('swalMessages');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Applicant', function($stateParams, Applicant) {
                    return Applicant.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'applicant',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('applicant-detail.edit', {
            parent: 'applicant-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/applicant/applicant-dialog.html',
                    controller: 'ApplicantDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Applicant', function(Applicant) {
                            return Applicant.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('applicant.new', {
            parent: 'applicant',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/applicant/applicant-dialog.html',
                    controller: 'ApplicantDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                dateOfBirth: null,
                                email: null,
                                primaryContact: null,
                                secondaryContact: null,
                                nuit: null,
                                image: null,
                                imageContentType: null,
                                gender: null,
                                maritalStaus: null,
                                facebook: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('applicant', null, { reload: 'applicant' });
                }, function() {
                    $state.go('applicant');
                });
            }]
        }).state('applicant.create', {
            parent: 'applicant',
            url: '/create',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'hrmApp.applicant.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/applicant/applicant-profile.html',
                    controller: 'ApplicantCreateDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        dateOfBirth: null,
                        email: null,
                        primaryContact: null,
                        secondaryContact: null,
                        nuit: null,
                        image: null,
                        imageContentType: null,
                        gender: null,
                        maritalStaus: null,
                        facebook: null,
                        id: null
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicant');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('academicDegree');
                    $translatePartialLoader.addPart('academicLevel');
                    $translatePartialLoader.addPart('previousJob');
                    $translatePartialLoader.addPart('personDocument');
                    $translatePartialLoader.addPart('personDocumentType');
                    $translatePartialLoader.addPart('language');
                    $translatePartialLoader.addPart('swalMessages');
                    $translatePartialLoader.addPart('degree');
                    $translatePartialLoader.addPart('skill');
                    $translatePartialLoader.addPart('certification');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'applicant',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('applicant-detail.edit2', {
            parent: 'applicant-detail',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'hrmApp.applicant.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/applicant/applicant-profile.html',
                    controller: 'ApplicantEditDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicant');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('maritalStatus');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('academicDegree');
                    $translatePartialLoader.addPart('academicLevel');
                    $translatePartialLoader.addPart('previousJob');
                    $translatePartialLoader.addPart('personDocument');
                    $translatePartialLoader.addPart('personDocumentType');
                    $translatePartialLoader.addPart('language');
                    $translatePartialLoader.addPart('swalMessages');
                    $translatePartialLoader.addPart('degree');
                    $translatePartialLoader.addPart('skill');
                    $translatePartialLoader.addPart('certification');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'applicant',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('applicant.edit', {
            parent: 'applicant',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/applicant/applicant-dialog.html',
                    controller: 'ApplicantDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Applicant', function(Applicant) {
                            return Applicant.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('applicant', null, { reload: 'applicant' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('applicant.delete', {
            parent: 'applicant',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/applicant/applicant-delete-dialog.html',
                    controller: 'ApplicantDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    params: {
                      id: null
                    },
                    resolve: {
                        entity: ['Applicant', function(Applicant) {
                            return Applicant.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('applicant', null, { reload: 'applicant' });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('applicant.sendEmail', {
            parent: 'applicant',
            url: '/send-email/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/applicant/send-applicant-email-dialog.html',
                    controller: 'SendApplicantEmailController',
                    controllerAs: 'vm',
                    size: 'lg',
                    params: {
                      id: null
                    },
                    resolve: {
                        entity: ['Applicant', function(Applicant) {
                            return Applicant.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('applicant', null, { reload: 'applicant' });
                }, function() {
                    //$state.go('^');
                });
            }]
        });
    }

})();
