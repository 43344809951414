(function() {
    'use strict';

    angular
        .module('hrmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('person-document', {
            parent: 'entity',
            url: '/person-document',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'hrmApp.personDocument.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/person-document/person-documents.html',
                    controller: 'PersonDocumentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('personDocument');
                    $translatePartialLoader.addPart('personDocumentType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('person-document-detail', {
            parent: 'person-document',
            url: '/person-document/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'hrmApp.personDocument.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/person-document/person-document-detail.html',
                    controller: 'PersonDocumentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('personDocument');
                    $translatePartialLoader.addPart('personDocumentType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PersonDocument', function($stateParams, PersonDocument) {
                    return PersonDocument.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'person-document',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('person-document-detail.edit', {
            parent: 'person-document-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/person-document/person-document-dialog.html',
                    controller: 'PersonDocumentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PersonDocument', function(PersonDocument) {
                            return PersonDocument.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('person-document.new', {
            parent: 'person-document',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/person-document/person-document-dialog.html',
                    controller: 'PersonDocumentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                number: null,
                                emissionDate: null,
                                expiryDate: null,
                                issuePlace: null,
                                personDocumentType: null,
                                file: null,
                                fileContentType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('person-document', null, { reload: 'person-document' });
                }, function() {
                    $state.go('person-document');
                });
            }]
        })
        .state('person-document.edit', {
            parent: 'person-document',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/person-document/person-document-dialog.html',
                    controller: 'PersonDocumentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PersonDocument', function(PersonDocument) {
                            return PersonDocument.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('person-document', null, { reload: 'person-document' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('person-document.delete', {
            parent: 'person-document',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/person-document/person-document-delete-dialog.html',
                    controller: 'PersonDocumentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PersonDocument', function(PersonDocument) {
                            return PersonDocument.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('person-document', null, { reload: 'person-document' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
