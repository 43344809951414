(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('ApplicantDetailController', ApplicantDetailController);

    ApplicantDetailController.$inject = ['$scope', '$rootScope', '$translate', 'Certification', 'DataUtils', 'entity', '$http', '$sce', '$state', 'PersonDocument', 'Language', 'AlertService'];

    function ApplicantDetailController($scope, $rootScope, $translate, Certification, DataUtils, entity, $http, $sce, $state, PersonDocument, Language, AlertService) {
        var vm = this;

        vm.applicant = entity.applicant;
        getDate();
        var unsubscribe = $rootScope.$on('hrmApp:applicantUpdate', function(event, result) {
            vm.applicant = result;
        });
        $scope.$on('$destroy', unsubscribe);
        vm.address = vm.applicant.address;
        $http({
            method: 'GET',
            url: '/api/academic-degrees-applicant/'+vm.applicant.id
        }).then(function (response) {
            vm.academicDegrees=response.data;
            //vm.percentage={width: vm.calcPercentage()+'%'};
            $http({
                method: 'GET',
                url: '/api/person-documents-applicant/'+vm.applicant.id
            }).then(function (response) {
                vm.personDocuments=response.data;
                //vm.percentage={width: vm.calcPercentage()+'%'};
                $http({
                    method: 'GET',
                    url: '/api/previous-jobs-applicant/'+vm.applicant.id
                }).then(function (response) {
                    vm.previousJobs=response.data;
                    //vm.percentage={width: vm.calcPercentage()+'%'};
                    $http({
                        method: 'GET',
                        url: '/api/skills-applicant/'+vm.applicant.id
                    }).then(function (response) {
                        $http({
                            method: 'GET',
                            url: '/api/certifications-applicant/'+vm.applicant.id
                        }).then(function (response) {
                            vm.certifications=response.data;
                        }, function errorCallback(response) {
                            AlertService.error("Erro!");
                        });
                        $http({
                            method: 'GET',
                            url: '/api/applicant-applications2/' + vm.applicant.id
                        }).then(function successCallback(result) {
                            vm.applications = result.data;
                        }, function (err) {
                        });
                        vm.skill=response.data[0];
                        vm.percentage={width: vm.calcPercentage()+'%'};
                        vm.percentValue = vm.calcPercentage();
                    }, function errorCallback(response) {
                        AlertService.error("Erro!");
                    });
                }, function errorCallback(response) {
                    AlertService.error("Erro!");
                });
            }, function errorCallback(response) {
                AlertService.error("Erro!");
            });
        }, function errorCallback(response) {
            AlertService.error("Erro!");
        });

        vm.calculateAge = function calculateAge(start, end) { // birthday is a date
            var brt = new Date(start);
            var brtEnd = null;
            if(end=='currentJob'){
                brtEnd = vm.currentDate;
            }else{
                brtEnd = new Date(end);
            }

            var ageDifMs = brtEnd - brt.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            var res = Math.abs(ageDate.getUTCFullYear() - 1970);
            if(res==0){
                return brtEnd.getMonth() - brt.getMonth()
                    + (12 * (brtEnd.getFullYear() - brt.getFullYear())) + ' Meses';
            }else{
                return res;
            }
        };

        vm.openFile = DataUtils.openFile;

        vm.calcPercentage = function () {
            var percentage = 0;
            //Tab 1 25%
            if(vm.applicant.name!=null){
                percentage=percentage+5;
            }
            if(vm.applicant.nuit!=null){
                percentage=percentage+2;
            }
            if(vm.applicant.docNumber!=null && vm.applicant.documentType!=null){
                percentage=percentage+3;
            }
            if(vm.applicant.primaryContact!=null && vm.applicant.email!=null ){
                percentage=percentage+5;
            }
            if(vm.address){
                percentage=percentage+10;
            }

            //Tab 2 20%
            if(vm.academicDegrees.length > 0){
                percentage=percentage+20;
            }

            //Tab 3 15%
            if(vm.previousJobs.length > 0){
                percentage=percentage+15;
            }

            //Tab 4 10%
            if(vm.applicant.languages.length > 0){
                percentage=percentage+5;
            }
            if(vm.skill.skills){
                percentage=percentage+5;
            }

            //Tab 5 20%
            if(vm.skill.profile){
                percentage=percentage+10;
            }
            if(vm.skill.hobbies){
                percentage=percentage+5;
            }
            if(vm.skill.objectives){
                percentage=percentage+5;
            }

            //Tab 6 10%
            if(vm.personDocuments.length > 0){
                percentage=percentage+10;
            }


            return percentage;
        };

        function getDate() {
            $http({
                method: 'GET',
                url: '/api/current-date'
            }).then(function successCallback(response) {
                vm.currentDate = new Date(response.data);
            }, function () {

            });
        }

        vm.openEntityFile = function (entity, type) {
            vm.isLoadingFile = true;
            $translate('hrmApp.swalMessages.fileNotFound').then(function (translation) {
                vm.fileNotFound = translation
            });
            if(type==='CERT'){
                Certification.get({id:entity.id}, function (result) {
                    if(result.file){
                        vm.openFile(result.fileContentType, result.file);
                        vm.isLoadingFile = false;
                    } else {
                        fileNotFound();
                    }
                })
            }
            if(type==='PD'){
                PersonDocument.get({id:entity.id}, function (result) {
                    if(result.file){
                        vm.openFile(result.fileContentType, result.file);
                        vm.isLoadingFile = false;
                    } else {
                        fileNotFound();
                    }
                })
            }
        };


        function fileNotFound() {
            swal({
                title: '',
                text: vm.fileNotFound,
                type: "warning"
            });
            vm.isLoadingFile = false;
        }

        vm.downloadCV = function () {
            vm.disablePdf = true;
            $http({
                method: 'GET',
                url: '/api/applicant-pdf/'+vm.applicant.id,
                responseType: 'arraybuffer'
            }).then(function (response) {
                vm.disablePdf = false;
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                vm.content = $sce.trustAsResourceUrl(fileURL);
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', fileURL);
                downloadLink.attr('download', 'Curriculum-Vitae.pdf');
                downloadLink[0].click();
            }, function (response) {
                vm.disablePdf = false;
            });
        }
        vm.clear = function () {
            $state.go('home');
        };
    }
})();
