(function() {
    'use strict';
    angular
        .module('hrmApp')
        .factory('Applicant', Applicant);

    Applicant.$inject = ['$resource', 'DateUtils'];

    function Applicant ($resource, DateUtils) {
        var resourceUrl =  'api/applicants/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateOfBirth = DateUtils.convertLocalDateFromServer(data.dateOfBirth);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateOfBirth = DateUtils.convertLocalDateToServer(copy.dateOfBirth);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateOfBirth = DateUtils.convertLocalDateToServer(copy.dateOfBirth);
                    return angular.toJson(copy);
                }
            },
            'getApplicantFromUser':{
                method: 'GET',
                url: 'api/get-applicant-from-user/:userId',
                isArray: false
            },
            'getApplicantData':{
                method: 'GET',
                url: 'api/get-applicant-from-user/:userId',
                isArray: false
            },
            'getApplicant':{
                method: 'GET',
                url: 'api/applicants/:id'
            },
            'getAllApplicants':{
                method: 'GET',
                url: 'api/applicants-all',
                isArray: true
            },
            'getAllApplicantsNotApplied':{
                method: 'GET',
                url: 'api/applicants-all/:vId',
                isArray: true
            },
            'getAllApplicantsHome':{
                method: 'GET',
                url: 'api/applicants-all-home',
                isArray: true
            },
            'getDataPercentage':{
                method: 'GET',
                url: 'api/applicants/data/:id'
            },
            'search':{
                method:'GET',
                url:'/api/applicants/search/:query',
                isArray: true
            },
            'imageUri':{
                method:'GET',
                url:'/api/applicant-image/:id'
            },
            'findOne':{
                method:'GET',
                url:'/api/applicants-get/:id',
                ignoreLoadingBar: true
            },
            'singleApplicationAction': {
                method: 'POST',
                url: 'api/single-application-actions'
            },
            'sendEmailToApplicant': {
                method: 'POST',
                url: 'api/send-email-to-applicant'
            },
            'getApplicantEmail': {
                method: 'GET',
                url: 'api/get-email/:id',
                isArray: true
            }

        });
    }
})();
