(function() {
    'use strict';

    angular
        .module('hrmApp')
        .controller('AlertViewController', AlertViewController);

    AlertViewController.$inject = ['$http', '$scope', '$interval'];

    function AlertViewController ($http, $scope, $interval) {
        var vm = this;
        vm.vacanciesCount = 0;
        getVacanciesCount();
        vm.countDown = 360;
        vm.counter = $interval(function(){
            vm.countDown--;

            if(vm.countDown==0){
                getVacanciesCount();
                vm.countDown = 360;
            }
        },1000,0);

        function getVacanciesCount(){
            $http.get('/api/vacancies/to-activate/'
            ).then(function successCallback(response) {
                vm.vacanciesCount = response.data;
            }, function errorCallback(response) {
                vm.fail=true;
            });
        }

        $scope.$on('updateCount', function(event) {
            //console.debug("Broadcast");
            getVacanciesCount();
        });
    }
})();
